import { objectToFormData } from "../../utils/Utils";
import { emptyApi as api } from "./emptyApi";

const servicesRtkApi = api.enhanceEndpoints({ addTagTypes: ["user"] }).injectEndpoints({
    endpoints: (build) => ({
        transcribeAudio: build.mutation<TranscripeAudioResponse, TranscribeAudioArgs>({
            query: (args) => ({
                url: "services/transcribe",
                method: "POST",
                params: args.params,
                body: objectToFormData(args.body)
            })
        }),
        readText: build.mutation<any, undefined>({
            query: (args) => ({
                url: "services/read-out",
                method: "POST",
                params: args
            })
        })
    }),
    overrideExisting: false
});

export default servicesRtkApi;

export type TranscribeAudioArgs = {
    params: {
        current_org_uuid: string;
    };
    body: {
        rec: Blob;
    };
};

export type TranscripeAudioResponse = {
    text: string;
};

export type ReadTextArgs = {
    text: string;
    voice: string;
};

export const { useTranscribeAudioMutation } = servicesRtkApi;
