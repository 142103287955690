import ComboBox from "components/ComboBox";
import { useGetValuesListQuery } from "state/api/settings";
import { RESET_VALUE, ResetSettingButton, useFetchSetting, useTriggerSettingChangeCallback } from "./settingsUtils";
import { useCurrentOrganizationId } from "state/GeneralSlice";
import SettingLabel from "./SettingLabel";

function Tools({ setting, onReset }) {
    return <>
        {setting.reset_button
            ? <div className="flex flex-grow justify-end items-center mb-2">
                <ResetSettingButton setting={setting} onReset={onReset} />
            </div>
            : <></>
        }
    </>
}

function PlainComboBox({ setting, value, setValue, onChange, onLoaded, choices = {} }) {
    useFetchSetting(setting, value, setValue, onLoaded);

    const triggerChange = useTriggerSettingChangeCallback(value, setValue, onChange);

    return <>
        {choices && <ComboBox
            LabelComponent={<SettingLabel setting={setting} className="mb-2" />}
            disabled={setting.disabled}
            name={setting.config_name}
            initialValue={value}
            items={Object.keys(choices)}
            descriptions={Object.values(choices)}
            onSelected={triggerChange}
            tools={<Tools setting={setting} onReset={() => { triggerChange(RESET_VALUE) }} />}
        />}
    </>
}

export default function SettingComboBox(props) {
    const { data: choices } = useGetValuesListQuery({ url: props.setting.values_list_endpoint }, { skip: !props.setting.values_list_endpoint });
    const values = choices ?? props.setting.values_list;

    return <PlainComboBox {...props} choices={values} />
}

export function PipelineComboBox(props) {
    const orgId = useCurrentOrganizationId();
    const { data: choices } = useGetValuesListQuery({ url: `/organizations/${orgId}/pipeline-types` });
    return <PlainComboBox {...props} choices={choices} />
}