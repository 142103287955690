import { useIsCucinelliSophia } from "utils/Utils";
import { CucinelliLogo } from "../ThemeManager";
import ChatBar from "../chat/ChatBar";
import DefaultLogo from "../ThemeManager/DefaultLogo";

export default function StartChatting() {
    const isSophia = useIsCucinelliSophia();

    return <div className="flex-grow flex flex-col items-center w-full justify-center">
        <div className="flex-[0.5]" />
        {isSophia && <CucinelliLogo className="max-h-20 h-full w-full mx-10" />}
        <div className="flex-[0.5]" />
        <div className="w-full flex flex-col items-center">
            {isSophia && <DefaultLogo className="mb-14 h-full max-h-8 mx-8" />}
            {!isSophia && <p className="text-2xl mb-4">Explore your knowledge base</p>}
            <div className="w-full"><ChatBar placeholder="Ask me anything" /></div>
        </div>
        <div className="flex-[1.5]" />
    </div >
}