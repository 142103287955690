import { useCallback } from "react";
import { useLazyGetDownloadLinkQuery } from "../../../state/api/collections";
import { useCurrentCollectionId } from "../../../state/GeneralSlice";

const SourceDownloadLink = ({ fileUuid, filePage, children }) => {
    const [generateDownloadLink] = useLazyGetDownloadLinkQuery()
    const collId = useCurrentCollectionId();

    const onClick = useCallback(() => {
        const windowRef = window.open(undefined, '_blank');
        generateDownloadLink({
            collection_id: collId,
            file_id: fileUuid
        }).then((downloadLink) => {
            let myLink = downloadLink.data;
            if (filePage && filePage > 0) {
                myLink += "#page=" + filePage;
            }
            windowRef.location = myLink;
        });
    }, [collId, filePage, fileUuid, generateDownloadLink])

    return <span className="cursor-pointer text-azure-pastel transition-colors hover:text-white select-none" onClick={onClick}>
        {children}
    </span>
}

export default SourceDownloadLink;