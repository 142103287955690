import React, { useEffect } from "react";
import ComboBox from "../../ComboBox";
import { useIsCucinelliCollection, useIsOrgAdacto, useIsOrgMarchesini, useIsOrgZenodoto } from "../../../state/GeneralSlice";

// @ts-ignore
import { ReactComponent as SoloMeoLogo } from "./solomeo-logo.svg";
// @ts-ignore
import { ReactComponent as CucinelliLogoC } from "./bc-logo.svg";
// @ts-ignore
import marchesiniLogo from "./marchesini-logo.png";
import DefaultLogo from "./DefaultLogo";
import classNames from "classnames";
import { useIsCucinelliSophia } from "utils/Utils";
import { twMerge } from "tailwind-merge";

const ThemeTypes = {
    panna: "Panna (light theme)",
    blu: "Blu (dark theme)",
    default: "Default theme (system)"
};

type ThemeSelected = keyof typeof ThemeTypes;

export function useTheme() {
    const [theme, setTheme] = React.useState<ThemeSelected>("default");

    useEffect(() => {
        const initialDataTheme = document.body.getAttribute("data-theme");
        if (initialDataTheme && initialDataTheme in ThemeTypes) {
            setTheme(initialDataTheme as ThemeSelected);
        }
    }, []);

    const handleThemeChange = (theme: ThemeSelected) => {
        console.log("set theme", theme);

        document.body.setAttribute("data-theme", theme);
        localStorage.setItem("data-theme", theme);
        setTheme(theme as ThemeSelected);
    };

    return { theme, handleThemeChange };
}

export function Logo({ isSidebar = true, className }: { isSidebar: boolean; className?: string }) {
    // TODO: TEMPORARY IMPLEMENTATION
    // TODO: Refactor this from build stage

    const isSophia = useIsCucinelliSophia();
    const isCucinelliColl = useIsCucinelliCollection();
    const isAdactoOrg = useIsOrgAdacto();
    const isBrunelloOrg = useIsOrgZenodoto();
    const isMarchesiniOrg = useIsOrgMarchesini();

    if (isCucinelliColl) {
        if (isSophia)
            return (
                <div className="flex h-16 items-center w-full justify-center">
                    <DefaultLogo />
                </div>
            );
        return <CucinelliLogo />;
    }

    if (isAdactoOrg) {
        return <></>;
    }

    if (isBrunelloOrg) {
        return <SoloMeoLogo className={"fill-white h-16 w-auto mx-auto"} />;
    }

    if (isMarchesiniOrg) {
        return (
            <div className={classNames("h-full mx-auto", { "p-4": isSidebar })}>
                <img src={marchesiniLogo} alt="logo" className="h-full"></img>
            </div>
        );
    }

    return (
        <div className="flex h-16 items-center w-full justify-center">
            <DefaultLogo className={className} />
        </div>
    );
}

export function CucinelliLogo({ className }: { className?: string }) {
    return <CucinelliLogoC className={twMerge("fill-white h-16 w-auto mx-auto", className)} />;
}

export function ThemeProvider() {
    const { theme, handleThemeChange } = useTheme();
    const isOrgZenodoto = useIsOrgZenodoto();

    useEffect(() => {
        function getFromLocalStorage(): ThemeSelected {
            const theme = localStorage.getItem("data-theme");
            if (theme) {
                return theme as ThemeSelected;
            } else {
                return "default";
            }
        }

        function fromOrganizationRules(): ThemeSelected {
            if (isOrgZenodoto) return "panna";
            return "default";
        }

        function handleOnChange() {
            // se user default -> organization default else user theme else system default theme
            const user = getFromLocalStorage();
            const organization = fromOrganizationRules();

            console.log(user, organization, "user, organization");

            if (user !== "default") return handleThemeChange(user);
            if (organization !== "default") return handleThemeChange(organization);
        }

        handleOnChange();
    }, [handleThemeChange, isOrgZenodoto, theme]);
}

export function ThemeCombobox() {
    const { theme, handleThemeChange } = useTheme();

    return (
        <ComboBox
            label="Seleziona il tema"
            initialValue={theme}
            items={Object.keys(ThemeTypes)}
            tools=""
            descriptions={Object.values(ThemeTypes)}
            onSelected={handleThemeChange}
        />
    );
}
