import classNames from "classnames";

export default function Loading({ size = "w-8 h-8", stroke = "border-4", className }) {
    return <div className={classNames("text-blue-lightest flex items-center justify-center", className)}>
        <div
            className={classNames(
                "animate-spin rounded-full border-solid border-r-transparent motion-reduce:animate-[spin_1.5s_linear_infinite]",
                size,
                stroke,
            )}
            role="status"
        />
    </div>;
}