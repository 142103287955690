import Buttoon from "../../Buttoon";
import Modal from "../Modal"
import { HandThumbUpIcon, HandThumbDownIcon } from '@heroicons/react/24/solid'

export default function ShowFeedbackModal({ message, isPositive, show, onClose }) {

    return <Modal show={show} onClose={onClose}>
        <form className="relative">
            <div className="text-xl text-white flex justify-items-start items-center gap-x-2 w-[34rem]">
                {isPositive
                    ? <HandThumbUpIcon className="h-6 stroke-blue-lightest fill-green-pastel" />
                    : <HandThumbDownIcon className="h-6 stroke-blue-lightest fill-orange-pastel" />
                }
                <h2 className="grow w-full">
                    This was your feedback
                </h2>
            </div>
            <div className="mt-3 overflow-hidden rounded-lg border-0 border-dashed border-blue-lightest shadow-sm bg-blue text-white">
                <textarea
                    rows={2}
                    name="feedback"
                    id="feedback"
                    disabled={true}
                    value={message}
                    className="block w-full h-48 resize-none border-0 placeholder:text-blue-lightest focus:ring-0 sm:text-sm sm:leading-6 bg-blue"
                    placeholder="Optional feedback..."
                />
            </div>

            <div className="flex items-center justify-end mt-2 gap-x-2">
                <Buttoon
                    type="button"
                    onClick={onClose}
                    className="shrink-0 w-16"
                >
                    Ok
                </Buttoon>
            </div>
        </form>
    </Modal>
}