import classNames from "classnames"
import { useEffect, useState, useRef, useCallback } from "react"
import { useGetOrganizationMembersQuery } from "../../../state/api/organizations";
import { useCurrentOrganizationId } from "../../../state/GeneralSlice";
import { useGetAllCollectionsQuery } from "../../../state/api/collections";
import Loading from "../Loading";
import PrivilegesByUser from "./PrivilegesByUser";
import SavePrivilegesButton from "./SavePrivilegesButton";
import { loadCollectionId } from "utils/Session";
import { useLoadPrivilegesCallback } from "./privilegesUtils";
import PrivilegesByCollection from "./PrivilegesByCollection";
import { PlusIcon } from "@heroicons/react/24/solid";
import NewCollectionModal from "../sidebar/NewCollectionModal";
import { withOrganizationPrivilegesRequired } from "utils/Privileges";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

const Tabs = ["By User", "By Collection",]

function Privileges({ organizationId }) {
    const [currentTab, setCurrentTab] = useState(Tabs[0]);
    const originalPrivileges = useRef({});
    const newPrivileges = useRef({});

    const oId = useCurrentOrganizationId();
    const orgId = organizationId || oId;
    const { data: members, isFetching: isFetchingMembers } = useGetOrganizationMembersQuery({ org_uuid: orgId }, { skip: !orgId });
    const { data: collections, isFetching: isFetchingCollections } = useGetAllCollectionsQuery({ org: orgId, validate_admin: true }, { skip: !orgId });

    const [loaded, setLoaded] = useState(false);
    const [organizationPrivileges, setOrganizationPrivileges] = useState();
    const loadPrivileges = useLoadPrivilegesCallback();

    useEffect(() => {
        if (isFetchingMembers || isFetchingCollections) return;
        if (!organizationPrivileges) loadPrivileges(orgId, collections, members, (organizationPrivileges, usersPrivileges) => {
            originalPrivileges.current = usersPrivileges;
            setOrganizationPrivileges(organizationPrivileges);
            setLoaded(true);
        });
    }, [collections, isFetchingCollections, isFetchingMembers, loadPrivileges, members, orgId, organizationPrivileges])

    const [showNotification, setShowNotification] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(true);
    const onSaved = useCallback(() => {
        setLoading(true);
        setShowNotification(true);
    }, []);
    const onCompleted = useCallback((result) => {
        setLoading(false);
        setSuccess(result);
        loadCollectionId();
    }, []);

    useEffect(() => {
        if (showNotification && !loading) {
            const timeout = setTimeout(() => setShowNotification(false), 2000);
            return () => clearTimeout(timeout);
        }
    }, [loading, showNotification]);

    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    return <div className="flex flex-col flex-grow">
        <div className="shrink-0">
            {organizationId &&
                <button className="flex items-center gap-x-2 text-blue-lightest hover:text-white"
                    title="Back"
                    onClick={() => navigate("../")}>
                    <ChevronLeftIcon className="w-6 h-6" />
                    <p className="text-xl lg:text-2xl mb-2 mt-1">Organization Overview</p>
                </button>
            }
            <div className="flex items-center justify-between">
                <div>
                    <h1 className="text-base font-semibold leading-6 text-white">Grant Privileges</h1>
                    <div className="mt-1">
                        <button
                            title="Add"
                            className="group flex items-center gap-x-2"
                            onClick={() => setShowModal(true)}
                        >
                            <PlusIcon className="text-blue-lightest group-hover:text-white stroke-blue-lightest group-hover:stroke-white h-6 w-6 z-10" />
                            <span className="text-blue-lightest group-hover:text-white">New Collection</span>
                        </button>
                        <NewCollectionModal show={showModal} setShow={setShowModal} organizationId={orgId} />
                    </div>
                </div>
                <div className="flex items-center gap-x-3 mx-1">
                    {showNotification &&
                        <>
                            {loading
                                ? <Loading />
                                : <span className={classNames(
                                    "font-bold",
                                    success ? "text-green-pastel" : "text-orange-pastel"
                                )}>
                                    {success ? "Privileges saved successfully!" : "Something went wrong!"}
                                </span>
                            }
                        </>
                    }
                    <div>
                        <SavePrivilegesButton
                            orgId={orgId}
                            newPrivileges={newPrivileges.current}
                            organizationPrivileges={organizationPrivileges}
                            disabled={loading}
                            onClick={onSaved}
                            onCompleted={onCompleted}
                        />
                    </div>
                </div>
            </div>
            <div className="block">
                <div className="border-b border-gray">
                    <nav className="-mb-px flex" aria-label="Tabs">
                        {Tabs.map((tab) => (
                            <button
                                key={tab}
                                onClick={() => setCurrentTab(tab)}
                                className={classNames(
                                    currentTab === tab
                                        ? 'border-white text-white text-base'
                                        : 'border-transparent text-blue-lightest hover:text-white text-sm',
                                    'w-full border-b-2 py-4 px-1 text-center font-medium'
                                )}
                                aria-current={currentTab === tab ? 'page' : undefined}
                            >
                                {tab}
                            </button>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
        <did className="relative flex-grow">
            <div className="absolute overflow-y-auto py-2 pr-3 bottom-0 top-0 left-0 right-0">
                {loaded
                    ? <>
                        {currentTab === Tabs[0]
                            ? <PrivilegesByUser
                                orgId={orgId}
                                members={members}
                                collections={collections}
                                originalPrivileges={originalPrivileges.current}
                                newPrivileges={newPrivileges.current}
                                organizationPrivileges={organizationPrivileges}
                                setOrganizationPrivileges={setOrganizationPrivileges}
                            />
                            : <PrivilegesByCollection
                                orgId={orgId}
                                members={members}
                                collections={collections}
                                originalPrivileges={originalPrivileges.current}
                                newPrivileges={newPrivileges.current}
                                organizationPrivileges={organizationPrivileges}
                                setOrganizationPrivileges={setOrganizationPrivileges}
                            />
                        }
                    </>
                    : <div className="py-8"><Loading /></div>
                }
            </div>
        </did>
    </div>
}

export default withOrganizationPrivilegesRequired((props) => <Privileges {...props} />, "isAdmin");