import { useCurrentOrganizationId } from "state/GeneralSlice";
import MembersDetails from "./MembersDetails";
import Header from "components/Header";
import Separator from "components/Separator";
import { withOrganizationPrivilegesRequired } from "utils/Privileges";

function Users() {
    const orgId = useCurrentOrganizationId();

    return <div className="flex flex-col flex-grow">
        <div className="shrink-0">
            <Header title="Users" subtitle="Manage organization users" />
        </div>
        <Separator className="mt-4" />
        <div className="relative flex-grow">
            <div className="absolute overflow-y-auto py-2 pr-3 bottom-0 top-0 left-0 right-0">
                <MembersDetails orgId={orgId} addMember={false} />
            </div>
        </div>
    </div>
}

export default withOrganizationPrivilegesRequired((props) => <Users {...props} />, "isAdmin");