import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import Buttoon from "components/Buttoon";
import { useCallback, useState, useMemo } from "react";
import { useCurrentCollectionId } from "state/GeneralSlice";
import { useCreateCollectionListMutation, useDeleteCollectionListMutation, useEditCollectionListMutation, useGetCollectionListsQuery, useGetListsEnabledQuery } from "state/api/collections";
import Modal from "../Modal";
import classNames from "classnames";
import { useCollectionPrivileges } from "utils/Privileges";
import { useMediaQuery } from "react-responsive";
import ComboBox from "components/ComboBox";
import ConfirmationDialog from "../ConfirmationDialog";

function CreateListModal({ show, setShow, list }) {
    const collId = useCurrentCollectionId();
    const [createList] = useCreateCollectionListMutation();
    const [editList] = useEditCollectionListMutation();
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    const handleSubmit = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        const title = event.target.title.value;
        const description = event.target.description.value;
        if (list) editList({ collection_id: collId, list_id: list.uuid, params: { title: title, description: description } })
        else createList({ collection_id: collId, params: { title: title, description: description } });
        setShow(false);
    }, [collId, createList, editList, list, setShow]);

    return <Modal show={show} >
        <form onSubmit={handleSubmit} className={classNames({ "w-[500px]": !isMobile })}>
            <p className="text-xl lg:text-2xl mb-1 mt-3 text-blue-lightest">{list ? "Edit List" : "Create New List"}</p>
            <div className="overflow-hidden rounded-lg border-0 border-dashed border-blue-lightest shadow-sm text-white">
                <label htmlFor="name" className="sr-only">
                    Title
                </label>
                <input
                    type="text"
                    name="title"
                    id="title"
                    defaultValue={list?.title}
                    required
                    className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-blue-lightest focus:ring-0 bg-blue"
                    placeholder="Title"
                />
                <label htmlFor="description" className="sr-only">
                    Description
                </label>
                <div className="border-t-2 border-blue-dark mx-2"></div>
                <textarea
                    rows={2}
                    name="description"
                    id="description"
                    defaultValue={list?.description}
                    className="block w-full h-24 resize-none border-0 rounded-b-lg placeholder:text-blue-lightest focus:ring-0 sm:text-sm sm:leading-6 bg-blue"
                    placeholder="Description..."
                />
            </div>

            <div className="flex items-center justify-end mt-2 gap-x-2">
                <Buttoon
                    type="submit"
                    disabled={false}
                    className="w-16"
                >
                    {list ? "Update" : "Create"}
                </Buttoon>
                <Buttoon
                    type="button"
                    onClick={() => setShow(false)}
                    className="shrink-0 w-16"
                >
                    Cancel
                </Buttoon>
            </div>
        </form>
    </Modal>
}

function CreateListButton() {
    const [showCreate, setShowCreate] = useState(false);

    return <>
        <Buttoon
            className="max-w-[16em] px-2 shrink-0 grow-1 group w-9"
            title="Create List"
            onClick={() => setShowCreate(true)} >
            <PlusIcon className="w-5 h-5 group-hover:text-green-pastel" />
        </Buttoon>
        <CreateListModal show={showCreate} setShow={setShowCreate} />
    </>
}

function EditListButton({ list }) {
    const [showEdit, setShowEdit] = useState(false);

    return <>
        <Buttoon
            className="max-w-[16em] px-2 shrink-0 grow-1 group w-9"
            title="Edit List"
            onClick={() => setShowEdit(true)} >
            <PencilIcon className="w-5 h-5 group-hover:text-yellow-pastel" />
        </Buttoon>
        <CreateListModal show={showEdit} setShow={setShowEdit} list={list} />
    </>
}

function DeleteListButton({ selectedList, onSelected }) {
    const collId = useCurrentCollectionId();
    const [showDelete, setShowDelete] = useState(false);

    const [deleteList] = useDeleteCollectionListMutation();
    const onConfirm = useCallback((confirm) => {
        if (!confirm) return;
        deleteList({ collection_id: collId, list_id: selectedList });
        onSelected("");
    }, [collId, deleteList, onSelected, selectedList]);

    return <>
        <Buttoon
            className="px-2 shrink-0 grow-1 group w-9"
            title="Delete List"
            onClick={() => setShowDelete(true)} >
            <TrashIcon className="w-5 h-5 group-hover:text-orange" />
        </Buttoon>
        <ConfirmationDialog show={showDelete} setShow={setShowDelete} onClose={onConfirm} />
    </>
}

//*****************************
function ListsCombobox({ lists, selectedList, onSelected }) {
    const items = useMemo(() => lists ? ["", ...lists.map(l => l.uuid)] : [""], [lists]);
    const titles = useMemo(() => lists ? ["All Documents", ...lists.map(l => l.title)] : ["All Documents"], [lists]);

    const { data: { writeAccess } } = useCollectionPrivileges();

    return <div className="flex w-full items-center justify-between gap-x-1">
        <div className={classNames("w-full", { "max-w-[calc(98vw-(40px*3))]": !!selectedList }, { "max-w-[calc(98vw-(40px))]": !selectedList })}>
            <ComboBox
                label=""
                items={items}
                descriptions={titles}
                initialValue={selectedList}
                onSelected={onSelected}
            />
        </div>
        {writeAccess && <div className="flex gap-x-1">
            <CreateListButton />
            {selectedList && <>
                <EditListButton list={lists.find(l => l.uuid === selectedList)} />
                <DeleteListButton selectedList={selectedList} onSelected={onSelected} />
            </>}
        </div>}
    </div>
}

function ListCarousel({ lists, selectedList, onSelected }) {
    const { data: { writeAccess } } = useCollectionPrivileges();

    return <div className="flex justify-between gap-x-4">
        <div className="flex gap-x-2 items-center overflow-x-auto pb-1">
            <Buttoon
                className={classNames("max-w-[16em] px-2 shrink-0 grow-1 truncate w-12", { "bg-blue-lightest": !selectedList })}
                title="All Documents"
                onClick={() => onSelected?.("")}>
                All
            </Buttoon>
            {lists && lists.map((l) => <Buttoon
                key={l.uuid}
                className={classNames("max-w-[16em] px-2 shrink-0 grow-1 truncate w-auto", { "bg-blue-lightest": selectedList === l.uuid })}
                title={l.title}
                onClick={() => onSelected?.(l.uuid)}>
                {l.title}
            </Buttoon>)}
        </div>
        {writeAccess && <div className="flex gap-x-2">
            <CreateListButton />
            {selectedList && <>
                <EditListButton list={lists.find(l => l.uuid === selectedList)} />
                <DeleteListButton selectedList={selectedList} onSelected={onSelected} />
            </>}
        </div>}
    </div>
}

export default function ListSelector({ selectedList, onSelected }) {
    const collId = useCurrentCollectionId();
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const { data: lists } = useGetCollectionListsQuery({ collection_id: collId }, { pollingInterval: 30000, refetchOnMountOrArgChange: true });
    const { data: enabled } = useGetListsEnabledQuery({ collection_id: collId }, { pollingInterval: 30000, refetchOnMountOrArgChange: true });

    return <>
        {enabled
            ? <>
                {isMobile
                    ? <ListsCombobox lists={lists} selectedList={selectedList} onSelected={onSelected} />
                    : <ListCarousel lists={lists} selectedList={selectedList} onSelected={onSelected} />
                }
            </>
            : <>
            </>
        }
    </>
}