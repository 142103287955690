// TODO: TEMPORARY IMPLEMENTATION
// TODO: Refactor this from build stage

// @ts-ignore
import { ReactComponent as SymboolicLogo } from "./symboolic-logo-v2.svg";

// @ts-ignore
import PaaiLogo from "./paaiLogo";

// @ts-ignore
import { ReactComponent as SophiaLogo } from "./sophia-logo.svg";

import React from "react";
import { useAppConfiguration } from "utils/Utils";
import { twMerge } from "tailwind-merge";

export default function DefaultLogo({ className = undefined }) {
  const config = useAppConfiguration();
  switch (config) {
    case "paai":
      return <PaaiLogo className={"fill-white h-10 w-auto mx-auto"} />;
    case "cucinelli_sophia":
      return <SophiaLogo className={twMerge("fill-white h-5 mx-2", className)} />;
    default:
      return <SymboolicLogo className={"fill-white h-5 w-auto mx-auto"} />;
  }
}