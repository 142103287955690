import { useContext, useEffect, useRef, useState } from "react";
import Modal from "../Modal";
import { MarkdownContext } from "./RawMarkdownMessage";

export default function MarkdownImage({ src, alt, isSource }) {
    const [show, setShow] = useState(false);
    const { loadedImages, setLoadedImages } = useContext(MarkdownContext);
    const ref = useRef();

    useEffect(() => {
        if (loadedImages[src] === true) return;
        const img = ref.current;
        img.onload = () => setLoadedImages((prev) => ({ ...prev, [src]: true }));
    }, [loadedImages, setLoadedImages, src])

    return <div>
        <button className="flex justify-center mx-2 my-12 cursor-zoom-in" onClick={() => setShow(true)}>
            <img ref={ref} src={src} alt={alt} className="max-w-[80%] rounded-sm shadow-xl" />
        </button>
        {!isSource &&
            <Modal show={show} onClose={() => setShow(false)}>
                <div className="mx-auto text-center flex justify-center">
                    <img src={src} alt={alt} className="rounded-sm shadow-xl" />
                </div>
            </Modal>
        }
    </div>
};