import { useCurrentCollectionId } from "state/GeneralSlice"
import { useGetDynamicSettingsTemplateMutation, useGetSettingsTemplateQuery, useSetMultipleCollectionConfigsMutation, useSetMultipleUserConfigsMutation } from "state/api/settings"
import Loading from "../Loading";
import Buttoon from "components/Buttoon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import _ from "lodash";
import { withCollectionPrivilegesRequired } from "utils/Privileges";
import ReactRouterPrompt from "react-router-prompt";
import ConfirmationDialog from "../ConfirmationDialog";
import SettingsContainer from "./SettingsContainer";
import { ManualConfigs } from "./SettingComponentesMap";
import useBus from 'use-bus';
import { generateId } from "utils/Utils";
import { SettingsContext } from "./settingsUtils";

function SettingsInternal({ onReinitialize }) {
    const collId = useCurrentCollectionId();
    const { currentData: settings, isFetching } = useGetSettingsTemplateQuery({ collectionId: collId });

    const [getSettingsTemplate, { data: newSettings }] = useGetDynamicSettingsTemplateMutation();
    const firstFetch = useRef(true);
    const [template, setTemplate] = useState();
    useEffect(() => {
        //console.log("FIRST FETCH", firstFetch.current, isFetching, settings?.settings, newSettings?.settings);
        if (firstFetch.current && settings?.settings) {
            firstFetch.current = false;
            setTemplate(settings.settings);
        }
        else {
            if (newSettings?.settings) setTemplate(newSettings.settings);
        }
    }, [isFetching, newSettings?.settings, settings?.settings])

    const [values, setValues] = useState({ manual_config: {}, current_config: {}, scope: {} });
    useEffect(() => {
        if (newSettings?.current_config) setValues(prev => ({ ...prev, current_config: { ...newSettings.current_config } }));
    }, [newSettings?.current_config])

    const [isLoaded, setLoaded] = useState(false);
    const [triggerChange, setTriggerChange] = useState(false);
    const onChange = useCallback(() => {
        setTriggerChange(true);
    }, []);
    useEffect(() => {
        if (triggerChange) {
            setTriggerChange(false);
            //setLoaded(false);
            //console.log(values);
            getSettingsTemplate({ collectionId: collId, body: values.current_config });
        }
    }, [collId, getSettingsTemplate, triggerChange, values])

    const isLoading = useMemo(() => isFetching || !isLoaded, [isFetching, isLoaded]);

    //console.log(isFetching, isUpdating, !isLoaded);

    const initialSettings = useRef();
    const cloneSettings = useCallback(() => {
        //console.log("CLONE");
        initialSettings.current = _.cloneDeep(values);
        //console.log(values, initialSettings.current, _.isEqual(values, initialSettings.current));
    }, [values]);

    useBus("apply-reset", cloneSettings, [cloneSettings]);

    useEffect(() => {
        if (!isLoading && !initialSettings.current) {
            cloneSettings();
        }
    }, [cloneSettings, isLoading]);

    const [isSaving, setSaving] = useState(false);
    const [savingStatus, setSavingStatus] = useState(undefined);
    const savingCompleted = useMemo(() => savingStatus !== undefined, [savingStatus]);
    const savingSuccedeed = useMemo(() => savingStatus === true, [savingStatus]);

    useEffect(() => {
        if (savingCompleted) {
            setSaving(false);
            cloneSettings();
        }
    }, [cloneSettings, savingCompleted]);

    useEffect(() => {
        if (savingCompleted) {
            const timeout = setTimeout(() => setSavingStatus(undefined), 3000);
            return () => {
                clearTimeout(timeout);
                if (savingSuccedeed) onReinitialize?.();
            };
        }
    }, [onReinitialize, savingCompleted, savingSuccedeed]);

    const [setCollectionConfigs] = useSetMultipleCollectionConfigsMutation();
    const [setUserConfigs] = useSetMultipleUserConfigsMutation();
    const saveSettings = useCallback(async (event) => {
        event.stopPropagation();
        event.preventDefault();
        setSavingStatus(undefined);
        setSaving(true);

        console.log("Saving settings", values);

        const collections = _.pick(values.current_config, values.scope.collection);
        const users = _.pick(values.current_config, values.scope.user);
        const manual = values.manual_config;

        //console.log(JSON.stringify(collections, null, 2));
        //console.log(JSON.stringify(users, null, 2));
        //console.log(JSON.stringify(manual, null, 2));

        const query = [];
        query.push(setCollectionConfigs({ collectionId: collId, body: collections }));
        query.push(setUserConfigs({ body: users }));
        for (const k in manual) {
            query.push(ManualConfigs[k](event.target, k));
        }

        let success = true;
        for (let i = 0; i < query.length; i++) {
            const response = await query[i];
            if (response.error) success = false;
        }

        setSavingStatus(success);
    }, [collId, setCollectionConfigs, setUserConfigs, values]);

    const [isInputValid, setInputValid] = useState(true);
    const isInvalid = useMemo(() => isInputValid === false, [isInputValid]);

    const onLoadingComplete = useCallback(() => setLoaded(true), []);

    return <>
        <form onSubmit={saveSettings} id="form" className={classNames("flex flex-col flex-grow gap-y-4")}>
            <div className="flex items-center justify-between shrink-0">
                <div>
                    <h1 className="text-base font-semibold leading-6 text-white">Collection Settings</h1>
                    <p className="mt-2 text-sm text-blue-lightest">
                        Change collection configuration
                    </p>
                </div>
                <div className="flex items-center gap-x-3">
                    {(isSaving || savingCompleted) &&
                        <>
                            {isSaving
                                ? <Loading />
                                : <span className={classNames(
                                    "font-bold",
                                    savingSuccedeed ? "text-green-pastel" : "text-orange-pastel"
                                )}>
                                    {savingSuccedeed ? "Collection settings saved successfully!" : "Something went wrong!"}
                                </span>
                            }
                        </>
                    }
                    {isInvalid && <span className="font-bold text-orange-pastel">Some configurations are invalid</span>}
                    <div>
                        <Buttoon type="submit" disabled={isLoading || isSaving || isInvalid || savingSuccedeed} >Save</Buttoon>
                    </div>
                </div>
            </div>

            <div className={classNames("relative border-t-[1px] border-blue-light flex-grow", { "opacity-30 pointer-events-none": isSaving || savingSuccedeed })}>
                <div className="absolute overflow-y-auto py-2 pr-3 bottom-0 top-0 left-0 right-0">

                    {isLoading && <Loading />}
                    <SettingsContext.Provider value={{ values, setValues }}>
                        <SettingsContainer
                            className={classNames({ "hidden": isLoading })}
                            template={template}

                            onChange={onChange}
                            onLoadingComplete={onLoadingComplete}
                            onValidInput={setInputValid}
                        />
                    </SettingsContext.Provider>
                </div>
            </div>
        </form>
        <ReactRouterPrompt when={() => !_.isEqual(values, initialSettings.current)}>
            {(props) => <NotSavedPrompt {...props} />}
        </ReactRouterPrompt>
    </>
}

function NotSavedPrompt({ isActive, onConfirm, onCancel }) {
    const handleConfirm = useCallback((confirm) => {
        if (confirm) onConfirm();
        else onCancel();
    }, [onCancel, onConfirm])

    return <ConfirmationDialog
        show={isActive}
        title="Warning!"
        text={<span>Unsaved changes will be lost.<br />Do you want to proceed anyway?</span>}
        confirmText="Continue"
        onClose={handleConfirm}
    />
}

function Settings(props) {
    const [key, setKey] = useState(generateId());

    const onReinitialize = useCallback(() => setKey(generateId()), []);

    return <SettingsInternal key={key} onReinitialize={onReinitialize} {...props} />
}

export default withCollectionPrivilegesRequired((props) => <Settings {...props} />, "adminAccess");