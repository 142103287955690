import { useState, useMemo, useRef, useEffect } from "react";
import MarkdownMessage from "../markdown/MarkdownMessage";
import { SettingContainerWrapper } from "./SettingsContainer";
import classNames from "classnames";
import { useMediaQuery } from "@uidotdev/usehooks";
import ComboBox from "components/ComboBox";
import Swiitch from "./Swiitch";
import { useFetchSetting, useTriggerSettingChangeCallback } from "./settingsUtils";
import RenderComponents from "./SettingsComponent";

function GroupContainer({ description, tools, children }) {
    const [title, setTitle] = useState("");
    const descriptionRef = useRef();
    useEffect(() => {
        setTitle(descriptionRef.current.textContent);
    }, [])

    return <div>
        <div className="flex items-end gap-x-1">
            <div className={classNames("h-3 border-2 border-b-0 border-r-0 rounded-tl-md border-blue-light", tools ? "w-2" : "w-4")} />
            <h1
                ref={descriptionRef}
                title={title}
                className={classNames("truncate", tools ? "text-sm font-medium leading-6" : "font-medium text-xl")}>
                <MarkdownMessage className="truncate" md={description} />
            </h1>
            {tools && <>
                <div className="flex-1 h-3 border-t-2 border-blue-light" />
                {tools}
            </>}
            <div className={classNames("h-3 border-2 border-b-0 border-l-0 rounded-tr-md border-blue-light", tools ? "w-2" : "flex-1")} />
        </div>
        <div className="border-2 rounded-b-md p-2 border-blue-light border-t-0">
            {children}
        </div>
    </div>
}

//*************************************/
export function SettingGroupContainer(props) {
    return <GroupContainer description={props.setting.description}>
        <SettingContainerWrapper {...props} />
    </GroupContainer>
}

//*************************************/
export function SettingRadioContainer(props) {
    return <GroupContainer description={props.setting.description}>
        <SettingContainerWrapper {...props} />
    </GroupContainer>
}

//*************************************/
function FeatureContainerSwitch(props) {
    const { setting, value, setValue, onChange, onLoaded } = props;

    useFetchSetting(setting, value, setValue, onLoaded);
    const triggerChange = useTriggerSettingChangeCallback(value, setValue, onChange);

    return <Swiitch checked={value} onChange={triggerChange} id={setting.config_name} disabled={setting.disabled} />
}

export function SettingsFeatureContainer(props) {
    return <GroupContainer description={props.setting.description} tools={<FeatureContainerSwitch {...props} />}>
        <SettingContainerWrapper {...props} />
    </GroupContainer>
}

//*************************************/
function TabsCombobox({ tabs, selectedTab, onSelected }) {
    const items = useMemo(() => tabs.map((el, idx) => idx), [tabs]);
    const titles = useMemo(() => tabs.map(el => el.description), [tabs]);

    return <div className="flex w-full items-center justify-between gap-x-1">
        <div className={classNames("w-full")}>
            <ComboBox
                label=""
                items={items}
                descriptions={titles}
                initialValue={selectedTab}
                onSelected={onSelected}
            />
        </div>
    </div>
}

function TabsCarousel({ tabs, selectedTab, onSelected }) {
    return <div className="block">
        <div className="bg-gray-darkest rounded-t-md border-b-[1px] border-blue-light">
            <nav className="flex" aria-label="Tabs">
                {tabs.map((tab, idx) => (
                    <button
                        key={idx}
                        type="button"
                        onClick={() => onSelected(idx)}
                        className={classNames(
                            selectedTab === idx
                                ? 'border-white text-white text-base'
                                : 'border-transparent text-blue-lightest hover:text-white',
                            'w-full py-2 border-b-2 px-1 text-center text-xs'
                        )}
                    >
                        <MarkdownMessage md={tab.description} />
                    </button>
                ))}
            </nav>
        </div>
    </div>
}

function TabsRender({ contents, isReady, isLoading, className, selectedTab, setSelectedTab, ...childProps }) {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    return <div>
        {isMobile
            ? <TabsCombobox tabs={contents} selectedTab={selectedTab} onSelected={(tab) => (setSelectedTab(tab))} />
            : <TabsCarousel tabs={contents} selectedTab={selectedTab} onSelected={(tab) => (setSelectedTab(tab))} />
        }

        {isReady && <div className={classNames("w-full h-full px-2 pt-3 pb-2", { "hidde": isLoading }, className)}>
            <RenderComponents contents={contents} classNameCallback={(idx) => classNames({ "hidden": idx !== selectedTab })} {...childProps} />
        </div>}
    </div>
}

export function SettingTabsContainer(props) {
    const [selectedTab, setSelectedTab] = useState(0);

    return <GroupContainer description={props.setting.description}>
        <SettingContainerWrapper {...props} Renderer={TabsRender} others={{
            selectedTab: selectedTab,
            setSelectedTab: setSelectedTab
        }} />
    </GroupContainer>
}