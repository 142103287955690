import Webcam from "react-webcam";
import { useRef, useCallback, useState, useEffect } from "react";
import Buttoon from "components/Buttoon";
import Modal from "../Modal";
import ComboBox from "components/ComboBox";
import Separator from "components/Separator";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import Editor from "components/lexical/Editor";
import Badge from "components/lexical/ui/Badge";
import { INSERT_QUOTE_COMMAND } from "components/lexical/plugin/quote/QuotePlugin";
import { createPortal } from "react-dom";
import AddWebUrlModal from "../knowledge/AddWebUrlModal";

const videoConstraints = {
    width: 1920,
    height: 1080,
    //facingMode: { exact: "environment" }
    //facingMode: "user"
};

export default function Laboratory() {
    const [deviceId, setDeviceId] = useState("");
    const [devices, setDevices] = useState([]);

    //Desktop
    const refeshDevices = useCallback(() => {
        navigator.mediaDevices
            .enumerateDevices()
            .then(mediaDevices => setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")));
    }, []);

    useEffect(
        () => refeshDevices(),
        [refeshDevices]
    );
    //console.log(devices);

    useEffect(() => {
        if (!deviceId) setDeviceId(devices.length > 0 ? devices[0].deviceId : "");
    }, [deviceId, devices])

    /*
    return (
        <>
            {devices.map((device, key) => (
                <div>
                    <Webcam audio={false} videoConstraints={{ deviceId: device.deviceId }} className="bg-white" />
                    {device.label || `Device ${key + 1}`}
                </div>
            ))}
        </>
    );
                */

    const webcamRef = useRef(null);
    const [img, setImg] = useState();
    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            console.log(imageSrc);
            setImg(imageSrc);
        },
        [webcamRef]
    );

    const [show, setShow] = useState(false);
    //console.log(deviceId);

    const editorRef = useRef();
    const onClick = () => {
        /*editorRef.current.dispatchCommand(INSERT_IMAGE_COMMAND, {
            text: "anodo",
            src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQA0dNHQFa3RAx4rKI7njb7BWDsya11kaxtp198Uuz2lR3Ib4ylCk4xkS9at-nR2JORwgM&usqp=CAU"
        });
        */

        editorRef.current.dispatchCommand(INSERT_QUOTE_COMMAND, {
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        })
    };

    /*
    const data = [
        [{ value: "Vanilla" }, { value: "Chocolate" }],
        [{ value: "Strawberry" }, { value: "Cookies" }],
    ];
    */
    const containerRef = useRef();

    const [showScraper, setShowScraper] = useState(false);

    return <div className="py-2 mx-auto min-w-7xl max-w-7xl h-full flex flex-col">
        <Buttoon onClick={() => setShow(true)}>Take a Pic</Buttoon>

        <div className="my-2">
            <Editor className="w-full px-2 py-3 resize-none text-white placeholder-blue-lightest bg-blue-dark rounded-l-md border-0 focus:ring-0 outline-none" editorRef={editorRef} />
            <button onClick={onClick}>TEST BUTTON</button>
        </div>

        <div className="my-2"></div>

        <Separator className="my-2" />
        <div className="bg-orange overflow-auto h-20 pt-10">
            <Badge color="pink" />
        </div>

        <Separator className="my-2" />

        <div ref={containerRef} className="relative">
            <div className="w-full bg-green relative overflow-hidden">
                <div className="w-20 h-20 bg-black relative">
                    <Child container={containerRef.current} />
                </div>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </div>
        </div>

        <Separator className="my-2" />
        <Buttoon onClick={() => setShowScraper(true)}>Url Scraper</Buttoon>
        <AddWebUrlModal show={showScraper} setShow={setShowScraper} />
        <Separator className="my-2" />
        <Separator className="my-2" />
        <Modal show={show}>
            <div className="flex flex-col gap-y-2">
                <div className="flex justify-between items-end">
                    <ComboBox
                        label="Select Video Input"
                        items={devices.map(d => d.deviceId)}
                        descriptions={devices.map(d => d.label)}
                        initialValue={deviceId}
                        onSelected={setDeviceId}
                    />
                    <button className="text-blue-light hover:text-white" title="Refresh" onClick={refeshDevices}>
                        <ArrowPathIcon className="w-8 h-8 m-1" />
                    </button>
                </div>
                <Separator />

                <div className="bg-blue-light max-w-full w-[1280px] rounded-md flex items-center aspect-video justify-center">
                    {img
                        ? <>
                            <img src={img} alt="Taken pic" />
                        </>
                        : <>
                            {deviceId
                                ? <Webcam
                                    audio={false}
                                    height={720}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    mirrored={true}
                                    width={1280}
                                    videoConstraints={{ ...videoConstraints, deviceId: deviceId }}
                                    className="rounded-md"
                                />
                                : <span className="text-blue-dark text-2xl font-bold">
                                    No video input available
                                </span>
                            }

                        </>
                    }
                </div>
            </div>
            <div className="relative flex items-baseline justify-end mt-2 gap-x-2">
                <Buttoon
                    className="shrink-0 w-16"
                    onClick={capture}
                >
                    Take
                </Buttoon>
                <Buttoon
                    type="button"
                    onClick={() => setShow(false)}
                    className="shrink-0 w-16"
                >
                    Cancel
                </Buttoon>
            </div>
        </Modal>
    </div>
}

function Child({ container }) {

    const ref = useRef();
    const elementRef = useRef();
    useEffect(() => {
        if (container && elementRef.current) {
            const containerRect = container.getBoundingClientRect();
            const childRect = ref.current.getBoundingClientRect();
            const dy = childRect.y - containerRect.y;
            const newY = containerRect.y + dy;
            console.log("newY", newY);
            elementRef.current.style.top = dy + "px";
        }
        console.log(ref.current.getBoundingClientRect(), container?.getBoundingClientRect());
    }, [container])

    return <>
        <div ref={ref} className="w-10 h-10 bg-pink absolute -top-5"></div>
        {container && createPortal(<div ref={elementRef} className="w-10 h-10 bg-yellow absolute -top-5 left-12"></div >, container)}
    </>
}