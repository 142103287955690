import { useParams } from "react-router-dom";
import History from "../chat/History";
import Title from "../chat/Title";
import Separator from "components/Separator";

export default function FeedbacksChat() {
    const { threadId } = useParams();
    return <div className="flex flex-col flex-grow">
        <Title feedbackThreadId={threadId} />
        <Separator />
        <History feedbackThreadId={threadId} />
    </div>
}
