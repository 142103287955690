import { useAuth0 } from '@auth0/auth0-react';
import { Route, BrowserRouter as Router, Routes, Outlet, Navigate } from 'react-router-dom';
import './App.css';
import { useEffect, useState } from 'react';
import { loadUserId } from 'utils/Session';
import HeaderProvider from "./components/HeaderProvider";
import DotecChat from "./components/content/dotec/DotecChat";
import { CreateDotecDistributorsChat } from "./components/content/dotec/CreateDotecChat";
import accessTokenService from "./utils/AccessTokenService";
import Loading from "./components/content/Loading";
import DotecDistributorsHomepage from 'components/content/dotec/DotecDistributorsHomepage';
import DirectOutDistributorsTotem from 'components/content/dotec/DirectOutDistributorsTotem';
import { useTheme } from 'components/content/ThemeManager';

function PrivateOutlet(props) {
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [appReady, setAppReady] = useState(false);

    useEffect(() => {
        async function triggerLoad() {
            if (isLoading) return;
            if (!getAccessTokenSilently) return;

            if (isAuthenticated) {
                await accessTokenService.AsyncSetAccessTokenFn(getAccessTokenSilently);
                await loadUserId();
                setAppReady(true);
            }
        }

        triggerLoad()
    }, [getAccessTokenSilently, isAuthenticated, isLoading]);



    if (isLoading) return <Loading />;
    if (!isAuthenticated) {
        console.debug("The user is not authenticated")
        return <Navigate to={"/"} replace />
    }
    if (!appReady) return <Loading />;
    return (
        <Outlet />
    );
}


function RedirectIfLoggedIn(props) {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) return <Loading />

    if (isAuthenticated) {
        return <Navigate to={props.to} replace />
    }

    return props.children;
}


function PublicRoutes() {
    return (
        <>
            <Route path="/" element={<RedirectIfLoggedIn to={"/platform"}><DotecDistributorsHomepage /></RedirectIfLoggedIn>} />
        </>
    );
}

function PrivateRoutes() {
    return (
        <>
            <Route path="platform/*" element={<PrivateOutlet />}>
                <Route path="" element={<DirectOutDistributorsTotem />} />
                <Route path=":threadId" element={<DotecChat />} />
                <Route path="distributors" element={<CreateDotecDistributorsChat />} />
            </Route>
        </>
    )
}

function App() {
    console.debug("Loaded APP DirectOut Distributors");

    const { handleThemeChange } = useTheme();
    useEffect(() => {
        handleThemeChange("default");
    }, [handleThemeChange])

    return (
        <HeaderProvider>
            <Router>
                <Routes>
                    {PublicRoutes()}
                    {PrivateRoutes()}
                </Routes>
            </Router>
        </HeaderProvider>
    );
}

export default App;