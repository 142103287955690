import {
    FolderIcon,
    HomeIcon,
    Cog6ToothIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
    UserGroupIcon,
    ArrowLeftOnRectangleIcon,
    PlusIcon,
    BuildingLibraryIcon,
    PresentationChartLineIcon,
    CommandLineIcon,
    FingerPrintIcon,
    BugAntIcon,
} from "@heroicons/react/24/outline";
import Avatar from "./Avatar";
import { NavLink } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import { useNavigate } from 'react-router-dom'
import { Threads } from "./content/sidebar/Threads";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useMemo, useState } from "react";
import { useGetAllCollectionsQuery, useGetCollectionInfoQuery } from "../state/api/collections";
import { useGetCurrentUserOrganizationsQuery, useGetOrganizationQuery } from "../state/api/organizations";
import { useDispatch } from "react-redux";
import {
    setCurrentCollectionId,
    setCurrentOrganizationId,
    useCurrentCollectionId,
    useCurrentOrganizationId,
    useIsCollectionIdValid,
    useIsOrgAdacto,
    useIsOrganizationIdValid
} from "../state/GeneralSlice";
import { useCollectionPrivileges, useOrganizationPrivileges } from "../utils/Privileges";
import NewCollectionModal from "./content/sidebar/NewCollectionModal";
import ManageCollection from "./content/sidebar/ManageCollection";
import { CogIcon } from "@heroicons/react/24/solid";
import { Logo } from "./content/ThemeManager";

export const SMALL_TITLE = "font-semibold text-blue-lightest px-2 ";
const TRANSITION_OFFSET = "translate-x-[120px]";
const TRANSITION_DURATION = "duration-300";

const SidebarNav = ({ name, to, icon: Icon }) => {
    return <span key={name}>
        <NavLink
            to={to}
            className={({ isActive }) => classNames(
                {
                    'bg-blue text-white': isActive,
                    'text-blue-lightest hover:text-white': !isActive
                },
                'group flex gap-x-3 rounded p-2 text-sm leading-6 font-semibold'
            )}
        >
            <Icon className="h-6 w-6 shrink-0" aria-hidden="true" />
            {name}
        </NavLink>
    </span>
}

const SelectorComponent = ({ currentItem, setShowList }) => {
    return <button
        className={SMALL_TITLE + "flex gap-x-2 items-center justify-between w-full rounded hover:text-white text-sm text-blue-lightest mb-1"}
        onClick={() => setShowList(true)}
        title={currentItem}
    >
        <div className="truncate">{currentItem}</div>
        <ChevronRightIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
    </button>
}

function useSelectElementCallback(action, setShowList) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectElement = useCallback((id) => {
        dispatch(action(id));
        setShowList(false);
        navigate("/");
    }, [action, dispatch, navigate, setShowList]);
    return selectElement;
}

const ListComponent = ({ title = "Selection", currentItemId, itemList, action, showList, setShowList, children, childCallback = () => { } }) => {
    const selectElement = useSelectElementCallback(action, setShowList);

    return <Transition
        show={showList}
        appear={true}
        enter={"ease-out " + TRANSITION_DURATION}
        enterFrom={"opacity-0 " + TRANSITION_OFFSET}
        enterTo="opacity-100 translate-x-0"
        leave={"ease-in " + TRANSITION_DURATION}
        leaveFrom="opacity-100 translate-x-0"
        leaveTo={"opacity-0 " + TRANSITION_OFFSET}
        className="absolute px-2 w-full h-full"
    >
        <div className="text-blue-lightest w-full h-full flex flex-col pb-3">
            <button
                className="flex text-md item-center gap-x-2 mb-4 hover:text-white"
                onClick={() => setShowList(false)}
            >
                <ChevronLeftIcon className="h-6 w-6" aria-hidden="true" />
                <span>{title}</span>
            </button>
            <div className=" overflow-auto">
                {
                    itemList.map((item) => {
                        return <div
                            key={item.uuid}
                            className={classNames("flex group hover:text-white justify-between items-center w-full", { "text-white font-bold": item.uuid === currentItemId })}
                            onClick={() => selectElement(item.uuid)}
                            title={item.name}
                        >
                            <button className="truncate w-full text-left">
                                {item.name.startsWith("PRIVATE") ? "PRIVATE" : item.name}
                            </button>
                            {childCallback(item)}
                        </div>
                    })
                }
            </div>
            {children}
        </div>
    </Transition>
}

//*************************/
const Organization = ({ setShowOrganizationsList }) => {
    const { data: { isAdmin } } = useOrganizationPrivileges();
    const isOrgValid = useIsOrganizationIdValid();
    return <>
        <div className={SMALL_TITLE + "text-xs text-white"}>Organization</div>
        <OrganizationSelector setShowOrganizationsList={setShowOrganizationsList} />
        {isOrgValid &&
            <>
                {isAdmin && <SidebarNav name="Dashboard" to="/dashboard" icon={HomeIcon} />}
                {isAdmin && <SidebarNav name="Privileges" to="/privileges" icon={FingerPrintIcon} />}
                {isAdmin && <SidebarNav name="Members" to="/members" icon={UserGroupIcon} />}
                {process.env.REACT_APP_TEST_PAGE_ENABLED === "true" && <SidebarNav name="Laboratory" to="/laboratory" icon={BugAntIcon} />}
            </>
        }
    </>
}

const OrganizationSelector = ({ setShowOrganizationsList }) => {
    const orgId = useCurrentOrganizationId();
    const isOrgValid = useIsOrganizationIdValid();
    const { data } = useGetOrganizationQuery({ org_uuid: orgId }, { skip: orgId === undefined });
    return <>
        {
            orgId && isOrgValid
                ? <SelectorComponent
                    currentItem={data?.name.startsWith("PRIVATE") ? "PRIVATE" : data?.name}
                    setShowList={setShowOrganizationsList}
                />
                : <div className={SMALL_TITLE + " text-sm"}>
                    <span>No Organizations</span>
                </div>
        }
    </>
}

const OrganizationsList = ({ showOrganizationsList, setShowOrganizationsList }) => {
    const orgId = useCurrentOrganizationId();
    const { data } = useGetCurrentUserOrganizationsQuery();
    const organizations = useMemo(() => data || [], [data]);
    return <ListComponent
        title="Select Organization"
        currentItemId={orgId}
        itemList={organizations}
        action={setCurrentOrganizationId}
        showList={showOrganizationsList}
        setShowList={setShowOrganizationsList}
    />
}

//*************************/
const CollectionSelector = ({ setShowCollectionsList }) => {
    const isOrgValid = useIsOrganizationIdValid();
    const collId = useCurrentCollectionId();
    const isCollValid = useIsCollectionIdValid();
    const { data: { isAdmin } } = useOrganizationPrivileges();
    const { data } = useGetCollectionInfoQuery({ collection_id: collId }, { skip: !collId });
    return <>
        {
            collId && isCollValid
                ? <SelectorComponent
                    currentItem={data?.name}
                    setShowList={setShowCollectionsList}
                />
                : <div className={SMALL_TITLE + " text-sm"}>
                    {isAdmin && isOrgValid
                        ? <NewCollection setShowCollectionsList={setShowCollectionsList} />
                        : <span>No Collections</span>
                    }
                </div>
        }
    </>
}

const NewCollection = ({ setShowCollectionsList }) => {
    const [showModal, setShowModal] = useState(false);
    const orgId = useCurrentOrganizationId();
    const selectElement = useSelectElementCallback(setCurrentCollectionId, setShowCollectionsList);

    return <>
        {orgId &&
            <>
                <button
                    title="Add"
                    className="group flex items-center gap-x-2"
                    onClick={() => setShowModal(true)}
                >
                    <PlusIcon className="text-blue-lightest group-hover:text-white stroke-blue-lightest group-hover:stroke-white h-6 w-6 z-10" />
                    <span className="text-blue-lightest group-hover:text-white">New Collection</span>
                </button>
                <NewCollectionModal show={showModal} setShow={setShowModal} setShowCollectionsList={setShowCollectionsList} onCreated={selectElement} />
            </>
        }
    </>
}

const CollectionsList = ({ showCollectionsList, setShowCollectionsList }) => {
    const collId = useCurrentCollectionId();
    const orgId = useCurrentOrganizationId();
    const { data: { isAdmin } } = useOrganizationPrivileges();
    const { data } = useGetAllCollectionsQuery({ org: orgId }, { skip: !orgId });
    const collections = useMemo(() => data || [], [data]);

    return <ListComponent
        title="Select Collection"
        currentItemId={collId}
        itemList={collections}
        action={setCurrentCollectionId}
        showList={showCollectionsList}
        setShowList={setShowCollectionsList}
        childCallback={(item) => <ManageCollection collection={item} />}
    >
        {isAdmin &&
            <>
                <div className="border-t border-blue mt-2 mb-3"></div>
                <NewCollection setShowCollectionsList={setShowCollectionsList} />
            </>
        }
    </ListComponent>
}

const Collections = ({ setShowCollectionsList }) => {
    const isCollValid = useIsCollectionIdValid();
    const { data: { readAccess, writeAccess, adminAccess } } = useCollectionPrivileges();
    return <>
        <div className={SMALL_TITLE + "text-xs text-white"}>Collection</div>
        <CollectionSelector setShowCollectionsList={setShowCollectionsList} />
        {isCollValid &&
            <>
                {(readAccess || writeAccess) && <SidebarNav name="Knowledge Base" to="/knowledgeBase" icon={FolderIcon} />}
                {adminAccess && <SidebarNav name="Settings" to="/settings" icon={Cog6ToothIcon} />}
                {adminAccess && <SidebarNav name="Feedbacks" to="/feedbacks" icon={PresentationChartLineIcon} />}
            </>
        }
    </>
}

//*************************/
function PopupMenu() {
    const navigate = useNavigate();
    const { user, logout } = useAuth0();
    const disconnect = useCallback(() => {
        localStorage.clear();
        logout({ logoutParams: { returnTo: window.location.origin } });
    }, [logout]);

    return <Popover className="">
        <Transition
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-12"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-12"
            className="w-full absolute z-50 bottom-2"
        >
            <Popover.Panel className="bg-blue border-white border-[1px] ">
                {process.env.REACT_APP_TOTEM_ENABLED === "true" &&
                    <button
                        className="flex w-full gap-x-3 px-2 py-3 text-sm font-semibold leading-6 text-white hover:bg-blue-light"
                        onClick={() => navigate("/totem")}
                    >
                        <CommandLineIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                        Totem
                    </button>
                }
                <button
                    className="flex w-full gap-x-3 px-2 py-3 text-sm font-semibold leading-6 text-white hover:bg-blue-light"
                    onClick={() => navigate("/admin")}
                >
                    <BuildingLibraryIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                    Administration
                </button>

                <Popover.Button as="button" className="flex w-full gap-x-3 px-2 py-3 text-sm font-semibold text-white leading-6 hover:bg-blue-light" onClick={() => navigate("/user-settings")}>
                    <CogIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                    User settings
                </Popover.Button>

                <button
                    className="flex w-full gap-x-3 px-2 py-3 text-sm font-semibold leading-6 text-white hover:bg-orange"
                    onClick={disconnect}
                >
                    <ArrowLeftOnRectangleIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                    Logout
                </button>
            </Popover.Panel>
        </Transition>

        <Popover.Button as="div">
            <div className="grounded flex w-full items-center gap-x-4 px-2 py-3 text-sm font-semibold leading-6 text-white hover:bg-blue">
                <Avatar />
                <span className="sr-only">Your profile</span>
                <span aria-hidden="true" className="truncate">{user.name}</span>
            </div>
        </Popover.Button>
    </Popover>
}

export default function Sidebar() {
    const orgValid = useIsOrganizationIdValid();
    const collValid = useIsCollectionIdValid();
    const { data: { chatAccess } } = useCollectionPrivileges();

    const [showCollectionsList, setShowCollectionsList] = useState(false);
    const [showOrganizationsList, setShowOrganizationsList] = useState(false);

    const showMenu = useMemo(() => !showCollectionsList && !showOrganizationsList, [showCollectionsList, showOrganizationsList]);

    const isAdactoOrg = useIsOrgAdacto();

    return <div className="flex flex-col gap-y-2 bg-container-sidebar px-1 w-52 overflow-hidden h-[100svh]">
        {
            !isAdactoOrg && (
                <NavLink to="/" className="flex shrink-0 items-center">
                    <Logo />
                </NavLink>
            )
        }

        <div className="relative h-full">
            <CollectionsList
                showCollectionsList={showCollectionsList}
                setShowCollectionsList={setShowCollectionsList}
            />
            <OrganizationsList
                showOrganizationsList={showOrganizationsList}
                setShowOrganizationsList={setShowOrganizationsList}
            />

            <Transition
                show={showMenu}
                appear={true}
                enter={"ease-out " + TRANSITION_DURATION}
                enterFrom="opacity-0 -translate-x-[120px]"
                enterTo="opacity-100 translate-x-0"
                leave={"ease-in " + TRANSITION_DURATION}
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 -translate-x-[120px]"
                as="nav"
                className="h-full"
            >
                <ul className="flex flex-col h-full">
                    <div className="flex-1">
                        {chatAccess && collValid && <Threads />}
                    </div>

                    <div className="border-t-2 border-blue m-2"></div>

                    <div className="grow-0 relative">
                        {orgValid && <>
                            <Collections setShowCollectionsList={setShowCollectionsList} />
                            <div className="border-t border-blue m-2"></div>
                        </>}
                        <Organization setShowOrganizationsList={setShowOrganizationsList} />
                        <div className="border-t border-blue mt-2 mb-1"></div>
                        <PopupMenu />
                    </div>
                </ul>
            </Transition>
        </div>
    </div>
}