import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Bars3Icon, HomeIcon } from '@heroicons/react/24/outline'
import Sidebar from "./Sidebar";
import Avatar from "./Avatar";
import Content from "./Content";
import { useLocation, useNavigate } from "react-router-dom";
import { load } from '../utils/Session';
import { Logo } from "./content/ThemeManager";
import { useIsCucinelliSophia, useUrl } from 'utils/Utils';
import classNames from 'classnames';

const Dashboard = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const location = useLocation()?.pathname;

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        setSidebarOpen(false);
    }, [location, setSidebarOpen])

    const { pathUrl } = useUrl();
    const navigate = useNavigate();
    useEffect(() => {
        if (location !== pathUrl) {
            console.log("navigating to", pathUrl);
            navigate(pathUrl);
        }
    }, [location, navigate, pathUrl]);

    const isSophia = useIsCucinelliSophia();
    const hiddenClass = classNames({ "lg:hidden": !isSophia });
    console.log("pathurl", pathUrl)
    return <div className="h-[100svh] w-[100svw] flex flex-col">
        <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className={classNames("relative z-50", hiddenClass)} onClose={setSidebarOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-900/80" />
                </Transition.Child>

                <div className="fixed inset-0 flex">
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <Dialog.Panel className="relative mr-16 flex w-full max-w-max flex-1">
                            <Sidebar />
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        {!isSophia && <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col">
            <Sidebar />
        </div>
        }

        <div className={classNames("z-40 flex items-center gap-x-0 bg-blue-dark px-2 md:px-6 shadow-sm w-full relative", hiddenClass)}>
            <button type="button" className={classNames("text-blue-lightest hover:text-white p-1", hiddenClass)} onClick={() => setSidebarOpen(true)}>
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-7 w-7" aria-hidden="true" />
            </button>

            <div className="w-full relative">
                <div className={classNames({ "invisible": pathUrl === "" || pathUrl === "/" })}>
                    <Logo isSidebar={false} className={"max-w-[50%]"} />
                </div>
            </div>

            {isSophia && <button
                className="absolute flex items-center gap-x-3 text-blue-lightest hover:text-white top-1/2 -translate-y-1/2 right-2 md:right-6"
                onClick={() => navigate("/")}
                title="Explore"
            >
                <HomeIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                <span className='invisible md:visible'>Home</span>
            </button>
            }

            <Avatar className={classNames({ "invisible": isSophia })} />
        </div>

        <main className={classNames("text-white flex-1 flex flex-col", { "lg:pl-52": !isSophia })}>
            <div className="mx-auto w-full max-w-7xl flex-1 flex flex-col py-4 px-4 lg:py-2">
                <Content />
            </div>
        </main>
    </div>
}

export default Dashboard;