import { useCallback, useEffect, useState } from 'react';
import Modal from '../Modal';
import { useLazyGetGistQuery, useLazyGetNoteQuery, useUpdateGistMutation, useUpdateNoteMutation, useUploadNoteMutation } from "../../../state/api/collections";
import Buttoon from '../../Buttoon';
import Loading from '../Loading';
import { useCurrentCollectionId, useCurrentOrganizationId } from '../../../state/GeneralSlice';

const MAX_CHAR = process.env.REACT_APP_NOTES_MAX_CHAR;

export default function AddNoteModal({ show, onClose, editDocument }) {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const orgId = useCurrentOrganizationId();
    const collId = useCurrentCollectionId();

    const [uploadNote] = useUploadNoteMutation();
    const [getNote, { isFetching: isFetchingNote }] = useLazyGetNoteQuery();
    const [updateNote] = useUpdateNoteMutation();

    const [getGist, { isFetching: isFetchingGist }] = useLazyGetGistQuery();
    const [updateGist] = useUpdateGistMutation();

    useEffect(() => {
        async function fetchDocument() {
            if (editDocument) {
                let data;
                switch (editDocument.filetype) {
                    case "md": {
                        data = (await getGist({ collection_id: collId, doc_uid: editDocument.filename })).data;
                        break;
                    }
                    case "note": {
                        data = (await getNote({ collection_id: collId, doc_uid: editDocument.filename })).data;
                        break;
                    }
                    default: {
                        break;
                    }
                }
                if (data) {
                    setTitle(data.title);
                    setContent(data.body);
                }
            };
        }
        fetchDocument();
    }, [collId, editDocument, getGist, getNote]);

    const handleSubmit = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        if (editDocument) {
            switch (editDocument.filetype) {
                case "md": {
                    updateGist({
                        current_org_uuid: orgId,
                        collection_id: collId,
                        doc_uid: editDocument.filename,
                        title: title.trim(),
                        markdown_text: content.trim(),
                    });
                    break;
                }
                case "note": {
                    updateNote({
                        current_org_uuid: orgId,
                        collection_id: collId,
                        doc_uid: editDocument.filename,
                        title: title.trim(),
                        markdown_text: content.trim(),
                    });
                    break;
                }
                default: {
                    break;
                }
            }
        }
        else {
            uploadNote({ current_org_uuid: orgId, collection_id: collId, title: title.trim(), markdown_text: content.trim() });
        }
        onClose();
    }, [collId, content, editDocument, onClose, orgId, title, updateGist, updateNote, uploadNote]);

    return (
        <Modal show={show} onClose={() => { }} className='lg:w-2/3'>
            {isFetchingNote || isFetchingGist
                ? <Loading />
                : <form onSubmit={handleSubmit} className="relative">
                    <div className="overflow-hidden rounded-lg border-0 border-dashed border-blue-lightest shadow-sm bg-blue text-white">
                        <label htmlFor="title" className="sr-only">
                            Title
                        </label>
                        <input
                            type="text"
                            name="title"
                            id="title"
                            value={title}
                            required
                            onChange={(e) => setTitle(e.target.value)}
                            className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-blue-lightest focus:ring-0 bg-blue"
                            placeholder="Title"
                        />
                        <label htmlFor="description" className="sr-only">
                            Content
                        </label>
                        <div className="border-t-2 border-blue-dark mx-2"></div>
                        <textarea
                            rows={2}
                            name="content"
                            id="content"
                            value={content}
                            required
                            onChange={(e) => { if (e.target.value.length <= MAX_CHAR) setContent(e.target.value) }}
                            className="block w-full h-[50vh] resize-none border-0 placeholder:text-blue-lightest focus:ring-0 sm:text-sm sm:leading-6 bg-blue"
                            placeholder="Write a note..."
                        />
                    </div>

                    <div className="relative flex items-baseline justify-end mt-2 gap-x-2">
                        <div className="absolute left-1 z-50 -top-2">
                            <div className="text-blue-lightest text-xs">{content.length} / {MAX_CHAR}</div>
                        </div>

                        <Buttoon
                            type="submit"
                            disabled={content.length === 0}
                            className="shrink-0 w-16"
                        >
                            {editDocument ? "Update" : "Add"}
                        </Buttoon>
                        <Buttoon
                            type="button"
                            onClick={onClose}
                            className="shrink-0 w-16"
                        >
                            Cancel
                        </Buttoon>
                    </div>
                </form>
            }
        </Modal>
    )
}
