const DOTEC_COLLECTION_PRODIGY_MC = "e87ad53f-0c44-4030-95b6-c86795dbd502"
const DOTEC_COLLECTION_PRODIGY_MP = "1e582c58-ee8d-4a33-a134-dd23184032a5"
const DOTEC_COLLECTION_PRODIGY_MX = "855a73d2-8f55-49aa-a1f0-30475eeda593"
const DOTEC_COLLECTION_DISTRIBUTORS = "979a1d79-37cc-4ac4-a9bb-d805a8ffe373"

export function useProdigyMcCollectionId() {
    return DOTEC_COLLECTION_PRODIGY_MC;
}

export function useProdigyMpCollectionId() {
    return DOTEC_COLLECTION_PRODIGY_MP;
}

export function useProdigyMxCollectionId() {
    return DOTEC_COLLECTION_PRODIGY_MX;
}

export function useDistributorsCollectionId() {
    return DOTEC_COLLECTION_DISTRIBUTORS;
}