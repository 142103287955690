import { useAuth0 } from "@auth0/auth0-react";
import { twMerge } from "tailwind-merge";

export default function Avatar({ className }) {
    const { user } = useAuth0()
    return <>
        <span className="sr-only">Your profile</span>
        <img
            className={twMerge("h-9 w-9 rounded-full stroke-azure stroke-2", className)}
            src={user.picture}
            alt="Avatar"
        />
    </>
}