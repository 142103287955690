import Modal from "../Modal";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import SourceDownloadLink from "./SourceDownloadLink";
import { useEffect, useMemo, useState } from "react";
import { processMarkdown } from "components/content/markdown/MarkdownUtils";
import _ from "lodash";
import RawMarkdownMessage from "../markdown/RawMarkdownMessage";
import ExternalUrlLink from "./ExteralUrlLink";
import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";
import DropDown from "components/DropDown";
import classNames from "classnames";

import { BiSolidQuoteAltRight } from "react-icons/bi";
import { useMediaQuery } from "react-responsive";

//************************
const RAW_SEPARATOR = "\x1F";

function groupRawPages(source) {
    const pages_raw = source?.metadata?.page_raw;
    if (!pages_raw) return [];
    const grouped = _.groupBy(pages_raw, 0);

    const rawPages = [];
    Object.keys(grouped).forEach((key) => {
        const el = grouped[key];
        const start = Math.min(...el.map((v) => v[1]));
        const end = Math.max(...el.map((v) => v[2]));
        rawPages.push({
            page: key,
            start: start,
            end: end,
        });
    });

    if (rawPages.length === 0) return rawPages;

    rawPages.sort((a, b) => a.page - b.page);

    const pages = [];
    let lastIdx = rawPages[0].start;
    rawPages.forEach((p) => {
        if (p.start > lastIdx) {
            pages.push({
                page: -1,
                start: lastIdx,
                end: p.start,
            })
        }

        pages.push(p);
        lastIdx = p.end;
    });

    return pages;
}

function addRawPagesSeparator(theString, rawPages) {
    if (!rawPages || rawPages.length === 0) return theString;

    const strings = [];
    rawPages.forEach((p) => {
        strings.push(theString.substring(p.start, p.end));
    });
    const out = strings.join(RAW_SEPARATOR);
    return out;
}

function splitProcessedSources(theString, rawPages) {
    if (!rawPages || rawPages.length === 0) return [{ page: -1, content: theString }];
    const pages = theString.split(RAW_SEPARATOR);
    const html = [];
    pages.forEach((p, idx) => html.push({ page: rawPages[idx].page, content: fixHtml(p) }));
    return html;
}

function fixHtml(html) {
    var div = document.createElement('div');
    div.innerHTML = html
    return (div.innerHTML);
}

//************************
function SourceChunck({ idx, content, source }) {
    return <div className={classNames("group rounded-md p-2 text-xs border hover:border-azure-light mt-6", idx % 2 === 0 ? "bg-blue border-blue" : "bg-blue-light border-blue-light")}>
        <RawMarkdownMessage
            className="text-gray truncate"
            content={content}
            source={source} />
    </div>
}

function SourceOpenButton({ source }) {
    return <div className="font-bold">
        {source?.metadata?.source_url
            ? <ExternalUrlLink url={source.metadata.source_url}>
                <div className="flex items-center">
                    OPEN
                    <ArrowTopRightOnSquareIcon className="ml-1 w-4 h-5" />
                </div>
            </ExternalUrlLink>
            :
            <SourceDownloadLink fileUuid={source?.metadata?.file_uid} filePage={source?.metadata?.page}>
                <div className="flex items-center">
                    OPEN
                    <ArrowTopRightOnSquareIcon className="ml-1 w-4 h-5" />
                </div>
            </SourceDownloadLink>

        }
    </div>
}

const Source = ({ source, idx }) => {
    const [sourcePages, setSourcePages] = useState([]);

    const content = useMemo(() => {
        const idx = source.page_content.indexOf("\n");
        return source.page_content.substring(idx + 1);
    }, [source.page_content]);

    useEffect(() => {
        const rawPages = groupRawPages(source);
        const separated = addRawPagesSeparator(content, rawPages);
        processMarkdown(separated).then((processed) => {
            setSourcePages(splitProcessedSources(processed, rawPages));
        });
    }, [content, source]);

    return <div key={idx} className="text-sm mb-2 last:mb-0 border-solid border-gray-dark/20 border rounded-md p-4 bg-blue/30">
        <DropDown
            title={`${idx + 1}) ${source?.metadata?.title} [${source?.metadata?.filetype}]`}
            toolbar={<SourceOpenButton source={source} />}
            showSeparator={false}
            className="mt-0"
            titleClassName="text-md font-medium"
        >
            {sourcePages.map((s, idx) =>
                <div key={idx} className="mt-2">
                    {s.page === -1
                        ? <RawMarkdownMessage className="text-gray text-xs truncate" content={s.content} source={source} />
                        : <> {isNaN(s.page)
                            ? <ExternalUrlLink url={s.page}>
                                <SourceChunck idx={idx} content={s.content} source={source} />
                            </ExternalUrlLink>
                            : <SourceDownloadLink fileUuid={source?.metadata?.file_uid} filePage={Number(s.page) + 1}>
                                <SourceChunck idx={idx} content={s.content} source={source} />
                            </SourceDownloadLink>
                        }</>
                    }
                </div>
            )}
        </DropDown>
    </div >
}

export default function Sources({ sources, className, sentence = false }) {
    const [showSources, setShowSources] = useState(false);

    const isMobile = useMediaQuery({ maxWidth: 600 });

    return <>
        {sources && sources.length > 0 && <span className={className}>
            {sentence
                ? <span className="inline-flex items-center mx-1">
                    <BiSolidQuoteAltRight
                        title="Sources"
                        className={classNames("w-4 h-4 fill-blue-lightest hover:fill-white cursor-pointer inline")}
                        onClick={() => setShowSources(true)}
                    />
                </span>
                : <DocumentMagnifyingGlassIcon
                    title="Sources"
                    className="w-4 h-4 stroke-blue-lightest hover:stroke-white cursor-pointer"
                    onClick={() => setShowSources(true)}
                />
            }

            <Modal showCross={isMobile} show={showSources} setShow={setShowSources} className="max-md:w-full max-md:max-w-[100%] lg:w-[700px] px-4 | bg-gray-dark/5 backdrop-blur dark:ring-1 dark:ring-inset dark:ring-white/10 ">
                <div className="text-white">
                    {sources && sources.map((el, idx) => <Source key={idx} source={el} idx={idx} />)}
                </div>
            </Modal>
        </span>
        }
    </>
}