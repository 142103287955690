import SettingLabel from "./SettingLabel";
import { RESET_VALUE, ResetSettingButton, useFetchSetting } from "./settingsUtils";

function SettingInput({ type, value, setValue, setting, onLoaded, converter = (v) => v }) {
    useFetchSetting(setting, value, setValue, onLoaded);

    return <div className="h-full w-full">
        <div className="flex items-center justify-between mb-2">
            <SettingLabel setting={setting} />
            {setting.reset_button && <ResetSettingButton setting={setting} onReset={() => setValue(RESET_VALUE)} />}
        </div>

        <input
            required
            type={type}
            id={setting.config_name}
            name={setting.config_name}
            value={value}
            onChange={(e) => setValue(converter(e.target.value))}
            className="w-full h-9 cursor-default rounded-md bg-blue py-1.5 pl-3 pr-10 text-left text-white shadow-sm sm:text-sm sm:leading-6 border-0 focus:ring-0"
        />
    </div>
}

export const SettingText = (props) => <SettingInput type="text" {...props} />
export const SettingNumber = (props) => <SettingInput type="number" {...props} converter={(v) => v ? Number(v) : ""} />