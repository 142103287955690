import { Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { twMerge } from 'tailwind-merge'

export default function DropDown({ children, title, titleContent, toolbar, alreadyOpen = false, showSeparator = true, className, titleClassName }) {
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        setShowContent(alreadyOpen);
    }, [alreadyOpen]);

    return <div className={twMerge("mt-4", className)}>

        <div className="flex items-center justify-between w-full truncate">
            <button className="group flex items-center gap-x-2 text-white mr-3 truncate" onClick={() => setShowContent(prev => !prev)}>
                {showContent
                    ? <ChevronUpIcon className="w-5 h-5 shrink-0" />
                    : <ChevronDownIcon className="w-5 h-5 shrink-0" />
                }
                {title && <p className={twMerge("font-bold text-lg transition-colors group-hover:text-white/50", titleClassName)}>{title}</p>}
                <span className="truncate flex-1">{titleContent}</span>
            </button>
            {toolbar}
        </div>


        <Transition
            show={showContent}
            enter="ease-out duration-100"
            enterFrom="opacity-0 -translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-4"
        >
            <div className="text-blue-lightest rounded">
                {children}
            </div>
        </Transition>
        {showSeparator && <div className="border-b-[1px] border-gray-dark mt-1" />}
    </div>
}