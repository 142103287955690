import { setCurrentCollectionId, useCurrentCollectionId } from "state/GeneralSlice";
import CreateChat from "../chat/NewChat";
import { useDistributorsCollectionId, useProdigyMcCollectionId, useProdigyMpCollectionId, useProdigyMxCollectionId } from "./dotectUtils";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

function CreateDotecChat({ collectionId }) {
    const current = useCurrentCollectionId();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setCurrentCollectionId(collectionId));
    }, [collectionId, dispatch])

    return <>
        {(collectionId && collectionId === current) &&
            <CreateChat collectionId={collectionId} />
        }
    </>
}

export function CreateDotecProdigyMcChat() {
    const collectionId = useProdigyMcCollectionId();
    return <CreateDotecChat collectionId={collectionId} />
}

export function CreateDotecProdigyMpChat() {
    const collectionId = useProdigyMpCollectionId();
    return <CreateDotecChat collectionId={collectionId} />
}

export function CreateDotecProdigyMxChat() {
    const collectionId = useProdigyMxCollectionId();
    return <CreateDotecChat collectionId={collectionId} />
}

export function CreateDotecDistributorsChat() {
    const collectionId = useDistributorsCollectionId();
    return <CreateDotecChat collectionId={collectionId} />
}
