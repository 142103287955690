import { useCallback, useEffect, useState } from "react";
import { useProvideFeedbackMutation } from "../../../state/api/threads";
import Buttoon from "../../Buttoon";
import Modal from "../Modal"
import { HandThumbUpIcon, HandThumbDownIcon, CheckCircleIcon } from '@heroicons/react/24/solid'

export default function SendFeedbackModal({ threadUid, messageUid, isPositive, show, onClose }) {
    const [isDone, setIsDone] = useState(false);
    const [sendFeedback] = useProvideFeedbackMutation();

    useEffect(() => {
        if (isDone) {
            const timeout = setTimeout(() => onClose(), 1500);
            return () => clearTimeout(timeout);
        }
    }, [isDone, onClose])

    const handleSubmit = useCallback(async (event) => {
        event.stopPropagation();
        event.preventDefault();
        const feedback = event.target.feedback.value;
        const response = await sendFeedback({
            thread_uuid: threadUid,
            message_uuid: messageUid,
            feedback_value: isPositive,
            feedback_string: feedback
        });
        if (!response.error) setIsDone(true);
    }, [sendFeedback, threadUid, messageUid, isPositive, setIsDone]);

    return <Modal show={show} onClose={() => { }}>
        {isDone
            ? <div className="w-full text-center text-white">
                <CheckCircleIcon className="w-full h-48 text-green" />
                <h2 className="text-2xl">
                    Thanks for you feedback!
                </h2>
            </div>
            : <form onSubmit={handleSubmit} className="relative">
                <div className="text-xl text-white flex justify-items-start items-center gap-x-2 w-[34rem]">
                    {isPositive
                        ? <HandThumbUpIcon className="h-6 stroke-blue-lightest fill-green-pastel" />
                        : <HandThumbDownIcon className="h-6 stroke-blue-lightest fill-orange-pastel" />
                    }
                    <h2 className="grow w-full">
                        {isPositive
                            ? "(Optional) Provide feedback on the answer"
                            : "(Optional) Provide feedback on the answer or correct it"
                        }
                    </h2>
                </div>
                <div className="mt-3 overflow-hidden rounded-lg border-0 border-dashed border-blue-lightest shadow-sm bg-blue text-white">
                    <textarea
                        rows={2}
                        name="feedback"
                        id="feedback"
                        className="block w-full h-48 resize-none border-0 placeholder:text-blue-lightest focus:ring-0 sm:text-sm sm:leading-6 bg-blue"
                        placeholder="Optional feedback..."
                    />
                </div>

                <div className="flex items-center justify-end mt-2 gap-x-2">
                    <Buttoon
                        type="submit"
                        className="shrink-0 w-16"
                    >
                        Send
                    </Buttoon>
                    <Buttoon
                        type="button"
                        onClick={onClose}
                        className="shrink-0 w-16"
                    >
                        Cancel
                    </Buttoon>
                </div>
            </form>
        }
    </Modal>
}