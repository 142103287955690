import { useCallback, useMemo } from "react";
import { useCurrentCollectionId, useCurrentOrganizationId } from "state/GeneralSlice"
import { useGetAllCollectionsQuery } from "state/api/collections";
import { useFetchSetting } from "./settingsUtils";
import classNames from "classnames";
import SettingLabel from "./SettingLabel";

function CollectionCheckbox({ collection, value, setValue }) {
    const updateSelection = useCallback((e) => {
        if (e.target.checked) setValue(prev => [...prev, collection.uuid]);
        else setValue(prev => prev.filter(v => v !== collection.uuid));
    }, [collection.uuid, setValue])

    return <div className="w-1/2 flex gap-x-1 items-center">
        <input
            name={collection.name}
            id={collection.uuid}
            type="checkbox"
            checked={value.includes(collection.uuid)}
            onChange={updateSelection}
            className="h-4 w-4 rounded border-blue-light text-blue-light bg-blue-dark focus:ring-0"
        />
        <label htmlFor="prompt" className="font-normal text-xs truncate pr-2">
            {collection.name}
        </label>
    </div>
}

export default function CollectionAssociation({ value, setValue, setting, onLoaded }) {
    const orgId = useCurrentOrganizationId();
    const { data } = useGetAllCollectionsQuery({ org: orgId });

    const collId = useCurrentCollectionId();
    const collections = useMemo(() => data ? data.filter(c => c.uuid !== collId) : [], [collId, data]);

    useFetchSetting(setting, value, setValue, onLoaded);

    return <div className="w-full">
        <SettingLabel setting={setting} />
        <div className={classNames("flex flex-wrap gap-y-1", { "opacity-50 pointer-events-none": setting.disabled })}>
            {collections.map(c => <CollectionCheckbox key={c.uuid} collection={c} value={value} setValue={setValue} />)}
        </div>
    </div>
}