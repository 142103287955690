import classNames from "classnames";
import style from "./MarkdownMessage.module.css";
import { createContext, useEffect, useMemo, useState } from "react";
import { convertHtmlToReact } from "components/content/markdown/MarkdownUtils";
import { isArray } from "lodash";

export const MarkdownContext = createContext();

function findImages(component, result) {
    if (component.key?.startsWith("img")) {
        result[component.props.src] = false;
    }

    const children = component.props?.children;
    if (!isArray(children)) return;

    children.forEach((c) => findImages(c, result));
}

export default function RawMarkdownMessage({ content, className, source, metadata, ...props }) {
    const [component, setComponent] = useState();
    const [loadedImages, setLoadedImages] = useState({ "src": false });
    useEffect(() => {
        //console.log("CT", content);
        const component = convertHtmlToReact(content, source, metadata);
        const images = {};
        findImages(component, images)
        setLoadedImages(images);
        setComponent(component);
    }, [content, metadata, source]);

    const isLoaded = useMemo(() => {
        const values = Object.values(loadedImages);
        if (values.length === 0) return true;
        return values.every(v => v === true);
    }, [loadedImages])

    const [loadedComponent, setLoadedComponent] = useState();
    useEffect(() => {
        if (isLoaded) {
            setLoadedComponent(component);
        }
    }, [component, isLoaded, loadedImages]);

    return (
        <span className={classNames(style.markdown, "w-full")} {...props}>
            <div className={classNames(className, { 'whitespace-pre-wrap': !!source })}>
                <MarkdownContext.Provider value={{ loadedImages, setLoadedImages }}>
                    {loadedComponent}
                </MarkdownContext.Provider>
            </div>
            {!isLoaded &&
                <div className="absolute hidden">
                    <MarkdownContext.Provider value={{ loadedImages, setLoadedImages }}>
                        {component}
                    </MarkdownContext.Provider>
                </div>
            }
        </span>
    );
}