import { useEffect } from "react";
import SettingLabel from "./SettingLabel";

export default function SettingAlert({ setting, onLoaded }) {
    useEffect(() => onLoaded?.(), [onLoaded]);

    return <div className="h-full w-full">
        <div className="bg-yellow relative rounded-md p-2">
            <SettingLabel setting={setting} className="text-center text-blue-dark" />
        </div>
    </div>
}