// TODO: TEMPORARY IMPLEMENTATION
// TODO: Refactor this from build stage

import * as React from "react";
const PaaiLogo = (props) => (
  <svg
    width={500}
    height={128}
    id="logo"
    viewBox="0 0 263.55 83.92"
    xmlns="http://www.w3.org/2000/svg"
    svg="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs id="defs21" />
    <path
      className="cls-1"
      d="M83.71,27.21c-7.69,0-13.94,6.25-13.94,13.94s6.25,13.94,13.94,13.94,13.94-6.25,13.94-13.94-6.25-13.94-13.94-13.94m0,35.32c-11.79,0-21.39-9.59-21.39-21.39s9.59-21.38,21.39-21.38,21.38,9.59,21.38,21.38-9.59,21.39-21.38,21.39"
      id="path2"
    />
    <path
      className="cls-1"
      d="M202.16,27.21c-7.69,0-13.94,6.25-13.94,13.94s6.25,13.94,13.94,13.94,13.94-6.25,13.94-13.94-6.25-13.94-13.94-13.94m0,35.32c-11.79,0-21.39-9.59-21.39-21.39s9.59-21.38,21.39-21.38,21.38,9.59,21.38,21.38-9.59,21.39-21.38,21.39"
      id="path4"
    />
    <path
      className="cls-1"
      d="M263.55,58.41v-20.46c0-10.03-8.01-18.18-17.85-18.18-3.88,0-7.47,1.28-10.4,3.44v-1h0c0-2.05-1.67-3.71-3.72-3.71s-3.72,1.66-3.72,3.7V58.41h0c.02,2.03,1.68,3.67,3.72,3.67s3.7-1.64,3.72-3.67h0v-.03h0v-20.43c0-5.92,4.67-10.74,10.4-10.74s10.4,4.82,10.4,10.74v20.42s0,0,0,0,0,0,0,0v.03h0c.02,2.03,1.67,3.67,3.72,3.67s3.7-1.64,3.72-3.67h0Z"
      id="path6"
    />
    <path
      className="cls-1"
      d="M205.64,51.86c2.83,0,5.13-2.29,5.13-5.13s-2.3-5.13-5.13-5.13-5.13,2.3-5.13,5.13,2.3,5.13,5.13,5.13"
      id="path8"
    />
    <path
      className="cls-1"
      d="M57.08,3.72h0c0-2.06-1.67-3.72-3.72-3.72s-3.72,1.67-3.72,3.72c0,0,0,0,0,0V58.45s0,0,0,0c0,2.06,1.67,3.72,3.72,3.72s3.72-1.67,3.72-3.72h0V3.72Z"
      id="path10"
    />
    <path
      className="cls-1"
      d="M144.87,41.15c0,7.69-6.25,13.94-13.94,13.94s-13.94-6.25-13.94-13.94h0c0-7.69,6.26-13.94,13.94-13.94s13.94,6.25,13.94,13.94m7.44,0c0-11.79-9.59-21.38-21.39-21.38-5.33,0-10.19,1.97-13.94,5.2V3.72h0C116.99,1.67,115.32,0,113.26,0s-3.72,1.67-3.72,3.72c0,0,0,0,0,0V41.14s0,0,0,0,0,0,0,0v17.29s0,0,0,0c0,2.06,1.67,3.72,3.72,3.72s3.72-1.67,3.72-3.72h0v-1.12c3.75,3.23,8.61,5.2,13.94,5.2,11.79,0,21.39-9.59,21.39-21.39"
      id="path12"
    />
    <path
      className="cls-1"
      d="M0,62.54s0,0,0,.01c0,0,0,.02,0,.03,.03,11.77,9.61,21.33,21.38,21.33s21.36-9.57,21.38-21.34c0,0,0-.01,0-.02,0,0,0,0,0,0,0,0,0,0,0-.01h0c-.01-2.04-1.67-3.7-3.72-3.7s-3.71,1.66-3.72,3.7h0c0,7.69-6.25,13.94-13.94,13.94s-13.94-6.25-13.94-13.94h0c-.01-2.04-1.67-3.7-3.72-3.7S.01,60.49,0,62.53H0m35.32-21.54c0,7.69-6.25,13.94-13.94,13.94s-13.94-6.25-13.94-13.94,6.25-13.94,13.94-13.94,13.94,6.25,13.94,13.94m7.21-2.98h.01v-14.68c0-2.06-1.67-3.72-3.72-3.72s-3.72,1.67-3.72,3.72c0,0,0,0,0,0v1.28c-3.72-3.12-8.5-5.01-13.72-5.01C9.59,19.61,0,29.2,0,40.99s9.59,21.38,21.38,21.38,21.38-9.59,21.38-21.38c0-1.01-.1-2-.23-2.98"
      id="path14"
    />
    <path
      className="cls-1"
      d="M177.07,19.76s0,0-.01,0c-.01,0-.02,0-.03,0-11.77,.03-21.33,9.61-21.33,21.38s9.57,21.36,21.34,21.38c0,0,.01,0,.02,0,0,0,0,0,0,0,0,0,0,0,.01,0h0c2.05-.01,3.7-1.67,3.7-3.72s-1.66-3.71-3.7-3.72h0c-7.69,0-13.94-6.26-13.94-13.94s6.25-13.94,13.94-13.94h0c2.05-.01,3.7-1.67,3.7-3.72s-1.66-3.71-3.7-3.72h0s0,0,0,0"
      id="path16"
    />
  </svg>
);
export default PaaiLogo;
