import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Modal from '../Modal';
import { ArrowPathIcon, ArrowsPointingInIcon, EyeIcon, MagnifyingGlassMinusIcon, MagnifyingGlassPlusIcon, NewspaperIcon, ScissorsIcon } from '@heroicons/react/24/outline';
import Buttoon from 'components/Buttoon';
import { usePagePreviewMutation, useSectionPreviewMutation } from 'state/api/localApi';
import { useGesture, usePinch } from '@use-gesture/react'
import { useMeasure } from '@uidotdev/usehooks';
import Moveable from "react-moveable";
import Loading from '../Loading';
import Separator from 'components/Separator';

export default function AddWebUrlModal({ show, onClose }) {
    const [url, setUrl] = useState("");
    const [validUrl, setValidUrl] = useState(false);
    const updateUrl = useCallback((e) => {
        const value = e.target.value;
        setUrl(value);
        setValidUrl(value && e.target.validity.valid);
    }, [])

    const [getPreview, { data: page, isLoading: isPageLoading }] = usePagePreviewMutation()

    const [getSection, { data: section, isLoading: isSectionLoading }] = useSectionPreviewMutation();
    const sectionPromise = useRef();
    const loadSection = useCallback((selection) => {
        sectionPromise.current?.abort();
        sectionPromise.current = getSection({ ...selection, url: url });
    }, [getSection, url])

    const submit = useCallback(async (event) => {
        event.stopPropagation();
        event.preventDefault();
    }, []);

    return (
        <Modal show={show} onClose={onClose} className="w-[95svw] h-[95svh] max-w-[95svw] max-h-[95svh]">
            <form onSubmit={submit} className="h-full flex flex-col">
                <div className='flex items-center justify-between'>
                    <label htmlFor="url" className="text-white text-lg font-medium">
                        Insert URL
                    </label>
                    {url && !validUrl && <span className='text-orange-pastel text-sm'>
                        Insert a valid URL
                    </span>}
                </div>
                <div className="rounded-lg border-0 border-dashed border-blue-lightest text-white flex items-center gap-x-2">
                    <input
                        value={url}
                        onChange={updateUrl}
                        type="url"
                        name="url"
                        id="url"
                        className="rounded-lg h-9 bg-blue border-0 placeholder:text-blue-lightest focus:ring-0 bg-blueshadow-sm flex-1"
                        placeholder="Inster URL"
                    />
                    <Buttoon className='w-12' onClick={() => getPreview({ url: url })} disabled={!validUrl}>
                        <ArrowPathIcon className="w-8 h-8" />
                    </Buttoon>
                </div>
                <Separator className='my-2' />

                <div className='flex-1 w-full flex gap-x-2'>
                    <ImageScroller title="Page Preview" source={page} selectable={true} loading={isPageLoading} onSelectionChange={loadSection} />
                    <Separator vertical={true} />
                    <ImageScroller title="Section Preview" source={section} loading={isSectionLoading} />
                </div>

                <div className="flex items-center justify-end mt-2">
                    <button
                        type="submit"
                        className="w-16 block rounded-md bg-blue px-3 py-2 text-center text-sm font-semibold text-white hover:bg-blue-light mr-2"
                    >
                        Ok
                    </button>
                    <button
                        type="button"
                        onClick={onClose}
                        className="w-16 block rounded-md bg-blue px-3 py-2 text-center text-sm font-semibold text-white hover:bg-blue-light"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </Modal >
    )
}

function ImageScroller({ title, source, selectable, loading = false, onSelectionChange }) {
    const maxScale = 10.0;
    const [containerRef, { width, height }] = useMeasure();
    const canvasRef = useRef();

    const [minScale, setMinScale] = useState(0.01);
    const [scale, setScale] = useState(1);
    const [scroll, setScroll] = useState({ x: 0.0, y: 0.0 });
    const [imageLoaded, setImageLoaded] = useState(false);

    const scrollRef = useRef();

    useEffect(() => {
        //Fix for safari mobile
        document.addEventListener('gesturestart', (e) => e.preventDefault())
        document.addEventListener('gesturechange', (e) => e.preventDefault())
    }, []);

    const [realSize, setRealSize] = useState({ width: 1, height: 1 });

    useGesture(
        {
            onPinch: ({ offset: [d] }) => {
                const sc = Math.min(Math.max(minScale, Math.abs(d)), maxScale);
                console.log("pinch", d);
                setScale(sc);
            },
            onScroll: ({ offset: [x, y] }) => {
                console.log("scroll", realSize.height, x, y);
                setScroll({ x, y });
            }
        },
        {
            target: scrollRef,
            pinch: { from: [scale, 0] },
            scroll: { from: [scroll.x, scroll.y] }
        }
    )

    useEffect(() => {
        const canvas = canvasRef.current;
        const scroll = scrollRef.current;
        canvas.width = scroll.clientWidth;
        canvas.height = scroll.clientHeight;
    }, [height, width])


    const imageRef = useRef(new Image());
    const translateX = useMemo(() => {
        const canvas = canvasRef.current;
        if (!canvas) return 0;
        const realWidth = realSize.width;
        return realWidth < canvas.width ? (canvas.width / 2) - (realWidth / 2) : 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [realSize.width, width]);
    const translateY = useMemo(() => {
        const canvas = canvasRef.current;
        if (!canvas) return 0;
        const realHeight = realSize.height;
        return realHeight < canvas.height ? (canvas.height / 2) - (realHeight / 2) : 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [realSize.height, height]);

    const [bounds, setBounds] = useState({ left: 0, top: 0, right: 0, bottom: 10, position: "css" })
    const computeBounds = useCallback(() => {
        const scroll = scrollRef.current;
        const rightBar = scroll.offsetWidth - scroll.clientWidth;
        const bottomBar = scroll.offsetHeight - scroll.clientHeight;

        const canvas = canvasRef.current;
        const rightOffset = translateX === 0 ? 0 : canvas.width - (translateX + realSize.width);

        const bounds = {
            left: translateX,
            top: 0,
            right: rightBar + rightOffset,
            bottom: bottomBar,
            position: "css"
        }

        console.log(bounds);
        setBounds(bounds);
    }, [realSize.width, translateX])

    useEffect(() => {
        computeBounds();
    }, [computeBounds])

    useEffect(() => {
        setImageLoaded(false);
        console.log("Loading image");
        const img = imageRef.current;
        img.src = source;
        img.onload = () => {
            const canvas = canvasRef.current;
            setRealSize({ width: img.width, height: img.height });
            setScroll({ x: 0, y: 0 });
            setScale((canvas.width - 1) / img.width);
            img.onload = null;
            console.log("Image loaded");
            setImageLoaded(true);
        }
    }, [source]);

    useEffect(() => {
        if (!imageLoaded) return;
        const canvas = canvasRef.current;
        const img = imageRef.current;
        if (img.height > img.width) {
            setMinScale((canvas.height - 1) / img.height);
        }
        else {
            setMinScale((canvas.width - 1) / img.width);
        }
    }, [imageLoaded])

    useEffect(() => {
        if (!imageLoaded) return;
        const img = imageRef.current;
        setRealSize({ width: img.width * scale, height: img.height * scale });
    }, [imageLoaded, scale])

    const clear = useCallback(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
        return context;
    }, [])

    useEffect(() => {
        if (loading) clear();
    }, [clear, loading])

    useEffect(() => {
        if (!imageLoaded) return;
        console.log("Drawing");
        const context = clear();
        context.save();
        context.translate(translateX - scroll.x, translateY - scroll.y);
        context.scale(scale, scale);
        context.drawImage(imageRef.current, 0, 0);
        context.restore();
    }, [clear, imageLoaded, scale, scroll.x, scroll.y, translateX, translateY])

    const targetRef = useRef();

    const [selection, setSelection] = useState({ x: 0, y: 0, width: 0, height: 0 });

    usePinch(({ offset: [d] }) => {
        const sc = Math.min(Math.max(minScale, Math.abs(d)), maxScale);
        console.log("pinch", d);
        setScale(sc)
    }, { target: targetRef, from: [scale, 0] });

    const moveableRef = useRef();

    /*
    useEffect(() => {
        const movable = moveableRef.current;
        movable.request("resizable", {
            offsetWidth: selection.width * scale,
            offsetHeight: selection.height * scale,
        }, true)
    }, [scale, selection.height, selection.width])
    */


    const updateSelection = useCallback(({ x, y, width, height }) => {
        setSelection(prev => ({
            x: x === undefined ? prev.x : Math.max(0, (x - bounds.left) / scale + scroll.x),
            y: y === undefined ? prev.y : Math.max(0, y / scale + scroll.y / scale),
            width: width === undefined ? prev.width : width / scale,
            height: height === undefined ? prev.height : height / scale,
        }))
    }, [bounds.left, scale, scroll.x, scroll.y])

    return <div className='flex flex-col w-full h-full'>
        <div className='flex items-center justify-between mb-1 pl-2 pr-4'>
            <span>
                {title}
            </span>
            <div className='flex items-center gap-x-4 h-full'>
                {selectable && <>
                    <div className='flex items-center gap-x-1'>
                        <Buttoon title='Toggle Section Preview' className='w-7 h-7 mr-1'><EyeIcon className='w-5 h-5' /></Buttoon>
                        <div className='flex items-center'>
                            <Buttoon title='Crop' className='w-7 h-7'><ScissorsIcon className='w-5 h-5' /></Buttoon>
                            <Buttoon title='Full Page' className='w-7 h-7'><NewspaperIcon className='w-5 h-5' /></Buttoon>
                        </div>
                    </div>
                    <Separator vertical={true} />
                </>}
                <div className='flex items-center gap-x-1'>
                    <Buttoon title='Zoom In' className='w-7 h-7'><MagnifyingGlassPlusIcon className='w-5 h-5' /></Buttoon>
                    <Buttoon title='Zoom Out' className='w-7 h-7'><MagnifyingGlassMinusIcon className='w-5 h-5' /></Buttoon>
                    <Buttoon title='Fit' className='w-7 h-7 ml-1'><ArrowsPointingInIcon className='w-5 h-5' /></Buttoon>
                </div>
            </div>
        </div>
        <div ref={containerRef} id="container" className='flex-1 flex w-full justify-between bg-pink relative'>
            <div ref={scrollRef} className='w-full h-full overflow-scroll relative bg-blue touch-pan-x touch-pan-y'>
                <div className='absolute' style={{ width: realSize.width, height: realSize.height }} />
            </div>
            <canvas ref={canvasRef} className='mx-auto bg-gray-darkest absolute pointer-events-none' />
            {loading && <div className='absolute w-full h-full flex items-center'>
                <Loading className="mx-auto" size='w-20 h-20' stroke="border-8" />
            </div>}
            <div ref={targetRef} className='w-1/2 h-1/2 bg-red mx-auto absolute' />
            {selectable && imageLoaded && <Moveable
                ref={moveableRef}
                target={targetRef}
                draggable={true}
                throttleDrag={1}
                edgeDraggable={false}
                startDragRotate={0}
                throttleDragRotate={0}
                resizable={true}
                keepRatio={false}
                snappable={true}
                bounds={bounds}
                edge={[]}
                onBound={e => {
                    updateSelection({
                        x: e.bounds.left ? bounds.left : undefined,
                        y: e.bounds.top ? bounds.top : undefined
                    });
                    console.log(e);
                }}
                onDrag={e => {
                    e.target.style.transform = e.transform;
                    updateSelection({ x: e.translate[0], y: e.translate[1], width: e.width, height: e.height });
                }}
                onResize={e => {
                    e.target.style.width = `${e.width}px`;
                    e.target.style.height = `${e.height}px`;
                    e.target.style.transform = e.drag.transform;
                    updateSelection({ x: e.drag.translate[0], y: e.drag.translate[1], width: e.width, height: e.height });
                }}
                scrollable={false}
                scrollOptions={{
                    container: scrollRef,
                    threshold: 10,
                    checkScrollEvent: false,
                    throttleTime: 10,
                }}
                onScroll={({ scrollContainer, direction }) => {
                    scrollContainer.scrollTo(scroll.x + direction[0] * 10, scroll.y + direction[1] * 10)
                }}
                onDragEnd={() => {
                    console.log("Fine drag");
                    onSelectionChange?.(selection);
                }}
                onResizeEnd={() => {
                    console.log("Fine resize");
                    onSelectionChange?.(selection);
                }}
            />}
        </div>
        <div className='flex justify-between bg-green hidden'>
            <div className='w-56'>
                <p>Scale: {scale}</p>
                <p>ScrollX: {scroll.x}</p>
                <p>ScrollY: {scroll.y}</p>
                <p>Width: {imageRef.current?.width}</p>
                <p>Heigth: {imageRef.current?.height}</p>
            </div>
            <div className='w-56'>
                <p>Selection:</p>
                <p>X: {selection.x}</p>
                <p>Y: {selection.y}</p>
                <p>Width: {selection.width}</p>
                <p>Height: {selection.height}</p>
            </div>
        </div>
    </div>
}