import React from "react";
import { twMerge } from "tailwind-merge";

function Buttoon({ children, className, ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>): React.JSX.Element {
    if (!props.type) props.type = "button";
    return (
        <button
            className={twMerge(
                "flex items-center justify-center",
                "text-center font-semibold text-sm text-white",
                "bg-blue rounded-md w-32 h-9 py-2",
                "disabled:opacity-30 hover:enabled:bg-blue-light",
                className
            )}
            {...props}
        >
            {children}
        </button>
    );
}

export default Buttoon;
