import React from "react";
import { ThemeCombobox } from "../ThemeManager";

function UserSettings() {
    return (
        <div className="h-full">
            <div className="flex items-center justify-between">
                <div>
                    <h1 className="text-base font-semibold leading-6 text-white">User settings</h1>
                    <p className="mt-2 text-sm text-blue-lightest">Change users configuration</p>
                </div>
                <div className="flex items-center gap-x-3"></div>
            </div>

            <div className="mt-4 mb-2 border-t-[1px] py-2 pb-2 border-blue-light"></div>

            <ThemeCombobox />
        </div>
    );
}

export default UserSettings;
