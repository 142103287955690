import { HandThumbUpIcon, HandThumbDownIcon } from '@heroicons/react/24/solid'
import SendFeedbackModal from "./SendFeedbackModal";
import classNames from "classnames";
import ShowFeedbackModal from "./ShowFeedbackModal";
import ArchiveFeedbackButton from "../feedbacks/ArchiveFeedbackButton";
import { useCallback, useRef, useState } from "react";

const SendMessageFeedback = ({ item, hasFeedback, checkFeedbacks = false }) => {
    const [showSendFeedback, setShowSendFeedback] = useState(false);
    const isPositive = useRef();

    const handleSendFeedback = useCallback((positive) => {
        isPositive.current = positive;
        setShowSendFeedback(true);
    }, [isPositive]);

    return <>
        {item.uuid && !hasFeedback && <div className="flex ml-auto gap-x-2">
            <button
                disabled={checkFeedbacks}
                onClick={() => handleSendFeedback(true)}
            >
                <HandThumbUpIcon
                    className={classNames("h-5 w-5 text-transparent stroke-blue-lightest", { "hover:fill-green-pastel": !checkFeedbacks })}
                />
            </button>
            <button
                disabled={checkFeedbacks}
                onClick={() => handleSendFeedback(false)}
            >
                <HandThumbDownIcon
                    className={classNames("h-5 w-5 text-transparent stroke-blue-lightest", { "hover:fill-orange-pastel": !checkFeedbacks })}
                />
            </button>
        </div>
        }
        <SendFeedbackModal
            show={showSendFeedback}
            onClose={() => setShowSendFeedback(false)}
            threadUid={item.thread_uuid}
            messageUid={item.uuid}
            isPositive={isPositive.current}
        />
    </>
}

const ShowMessageFeedback = ({ item }) => {
    const [showShowFeedback, setShowShowFeedback] = useState(false);

    const handleShowFeedback = useCallback(() => {
        setShowShowFeedback(true);
    }, []);

    return <div className="flex ml-auto gap-x-2">
        <button onClick={handleShowFeedback} disabled={!item.feedback_value}>
            <HandThumbUpIcon className={classNames("h-5 w-5 text-transparent stroke-blue-lightest", { "fill-green-pastel": item.feedback_value })} />
        </button>
        <button onClick={handleShowFeedback} disabled={item.feedback_value}>
            <HandThumbDownIcon className={classNames("h-5 w-5 text-transparent stroke-blue-lightest", { "fill-orange-pastel": !item.feedback_value })} />
        </button>
        <ShowFeedbackModal
            show={showShowFeedback}
            onClose={() => setShowShowFeedback(false)}
            isPositive={item.feedback_value}
            message={item.feedback_string}
        />
    </div>
}

export default function MessageFeedback({ item, hasFeedback = false, checkFeedbacks = false }) {

    return <div className="flex-1">
        <div id="feedback" className="flex w-full pt-4">
            {hasFeedback && <ShowMessageFeedback item={item} />}
            <SendMessageFeedback item={item} hasFeedback={hasFeedback} checkFeedbacks={checkFeedbacks} />
        </div>
        {checkFeedbacks && hasFeedback && <>
            <div className={classNames("flex w-full my-2", { "opacity-50": item.feedback_read })} >
                <p className={classNames("text-justify bg-blue-dark p-2 rounded w-full border-solid border-2", item.feedback_value ? "border-green-pastel" : "border-orange-pastel")}>
                    <span className="float-right h-full ml-2 flex items-end" style={{ "shapeOutside": "inset(calc(100% - 40px) 0 0)" }}>
                        <ArchiveFeedbackButton threadId={item.thread_uuid} messageId={item.uuid} archived={item.feedback_read} />
                    </span>
                    {item.feedback_string}
                </p>
            </div>
        </>
        }
    </div >
}