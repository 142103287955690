import { RESET_VALUE, ResetSettingButton, useFetchSetting, useTriggerSettingChangeCallback } from "./settingsUtils";
import Swiitch from "./Swiitch";
import SettingLabel from "./SettingLabel";

export default function SettingBoolean({ setting, value, setValue, onChange, onLoaded }) {
    useFetchSetting(setting, value, setValue, onLoaded);

    const triggerChange = useTriggerSettingChangeCallback(value, setValue, onChange);

    return <div className="h-full w-full">
        <div className="flex items-center justify-between mb-2 gap-x-2">
            <SettingLabel setting={setting} />
            <Swiitch checked={value} onChange={triggerChange} id={setting.config_name} disabled={setting.disabled} />
            {setting.reset_button && <ResetSettingButton setting={setting} onReset={() => triggerChange(RESET_VALUE)} />}
        </div>
    </div>
}