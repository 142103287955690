import React from 'react';
import Buttoon from "../../Buttoon";
import { ReactComponent as Logo } from "./directout.svg";
import { useAuth0 } from "@auth0/auth0-react";


function DotecDistributorsHomepage() {
    const { loginWithRedirect } = useAuth0();
    return <>
        <div className="w-full lg:w-1/2 mx-auto flex flex-col h-screen px-3 py-8 lg:pt-12 justify-between">
            <div className="mx-auto w-full fill-white">
                <Logo className="mx-auto" />
            </div>

            <div className="">
                <div className="flex gap-x-5 justify-center items-center h-full flex-col gap-y-5 lg:flex-row">
                    <Buttoon className="w-full h-auto" onClick={() => loginWithRedirect()}>
                        <div className="text-lg font-normal flex flex-col gap-y-3 px-3 items-center py-10">
                            {/* Image */}
                            <img
                                src="https://cdn.is.symboolic.com/DO/dotty-transparent-no-borders.png"
                                alt="Robot icon"
                                className="mb-3 w-[100px]" // Add margin bottom for separation
                            />

                            {/* Button text */}
                            <span>Start chatting with </span>
                            <span className="text-2xl font-bold">Distributors DirectOut</span>
                        </div>
                    </Buttoon>
                </div>
                <p className="text-center text-white">
                    By continuing to use this website, you confirm that you have read and agree to our{' '}
                    <a href="https://www.iubenda.com/terms-and-conditions/16666463" className="underline text-azure iubenda-noiframe iubenda-embed iubenda-noiframe " title="Terms and Conditions ">
                        Terms and Conditions
                    </a>,{' '}
                    <a href="https://www.iubenda.com/privacy-policy/16666463" className="underline text-azure">
                        Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a href="https://www.iubenda.com/privacy-policy/16666463/cookie-policy" className="underline text-azure">
                        Cookie Policy
                    </a>
                    .
                </p>
            </div>



            <div className="flex justify-center">

            </div>
        </div>
    </>;
}

export default DotecDistributorsHomepage;