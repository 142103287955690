import { useCallback, useRef } from "react";
import Prompt from "./Prompt";
import { useNavigate } from "react-router-dom";
import { useChatPath } from "utils/Utils";

export default function ChatBar({ placeholder }) {
    const editorRef = useRef();

    const path = useChatPath();
    const navigate = useNavigate();

    const onSubmit = useCallback((text) => {
        navigate(path + "/new", { state: { template: text, send: true } })
    }, [navigate, path])

    return <div>
        <Prompt editorRef={editorRef} onSubmit={onSubmit} isHome={true} placeholder={placeholder} />
    </div>
}