import Prompt from "./chat/Prompt";
import History from "./chat/History";
import { useLocation, useParams } from "react-router-dom";
import Title from "./chat/Title";
import Separator from "components/Separator";
import { useRef } from "react";
import { useIsArchivedThread } from "state/api/threads";
import { useCheckAndSetWorkspace } from "utils/Utils";
import Loading from "./Loading";
import LinkError from "./chat/LinkError";

export default function Chat({ threadId }) {
    const { orgId, collectionId, threadId: tId } = useParams();
    const id = tId || threadId;
    const { state } = useLocation();

    const editorRef = useRef();
    const { data: isArchived } = useIsArchivedThread(id);

    const { loading, valid, message } = useCheckAndSetWorkspace(orgId, collectionId, tId);

    return <div className="flex flex-col flex-grow">
        {loading
            ? <Loading />
            : <>
                {valid
                    ? <>
                        <Title uuid={id} />
                        <Separator className="mb-2" />
                        <History uuid={id} editorRef={editorRef} />
                        {!isArchived && <Prompt uuid={id} initialPrompt={state} editorRef={editorRef} />}
                    </>
                    : <LinkError message={message} />
                }
            </>}
    </div>
}