import DragDropFile from "./DragDropFile";
import Modal from '../Modal';

export default function AddDocumentModal({ show, onClose, threadId }) {
    return (
        <Modal show={show} className="lg:w-[50%]" onClose={() => { }}>
            <DragDropFile onClose={onClose} threadId={threadId} />
        </Modal>
    )
}
