import Buttoon from "components/Buttoon";
import { ReactComponent as Logo } from "./directout.svg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useDoIbcOrganizationId } from "./totemUtils";
import { setCurrentOrganizationId } from "state/GeneralSlice";

export default function Totem() {
    const orgId = useDoIbcOrganizationId();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setCurrentOrganizationId(orgId));
    }, [dispatch, orgId])

    const navigate = useNavigate();

    return (
        <div className="w-1/2 mx-auto flex flex-col h-screen py-8">
            <div className="mx-auto w-full fill-white mt-10">
                <Logo className="mx-auto w-[90%]" />
            </div>
            <div className="mt-8 flex-grow pb-[100px]">
                <div className="flex gap-x-10 justify-between items-center h-full">
                    <Buttoon className="w-full h-60" onClick={() => navigate("asm")}>
                        <div className="text-lg">
                            <span>Chat with</span>
                            <br />
                            <span className="text-2xl font-bold">
                                ASM
                            </span>
                        </div>
                    </Buttoon>
                    <Buttoon className="w-full h-60" onClick={() => navigate("prodigymp")}>
                        <div className="text-lg">
                            <span>Chat with</span>
                            <br />
                            <span className="text-2xl font-bold">
                                PRODIGY.MP support
                            </span>
                        </div>
                    </Buttoon>
                </div>
            </div>
        </div>
    );
}
