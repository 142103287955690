import { useNavigate, useParams } from "react-router-dom";
import Chat from "../Chat";
import Buttoon from "components/Buttoon";
import { setCurrentCollectionId, useCurrentCollectionId } from "state/GeneralSlice";
import { ReactComponent as Logo } from "./directout.svg";
import { useDistributorsCollectionId, useProdigyMcCollectionId, useProdigyMpCollectionId, useProdigyMxCollectionId } from "./dotectUtils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { readCollectionId } from "utils/Storage";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import ConfirmationDialog from "../ConfirmationDialog";

const ChatType = {
    PRODIGY_MC: { text: "PRODIGY.MC support", path: "../prodigymc" },
    PRODIGY_MP: { text: "PRODIGY.MP support", path: "../prodigymp" },
    PRODIGY_MX: { text: "PRODIGY.MX support", path: "../prodigymx" },
    DISTRIBUTORS: { text: "DISTRIBUTORS support", path: "../distributors" },
}

function useChatType() {
    const mcId = useProdigyMcCollectionId();
    const mpId = useProdigyMpCollectionId();
    const mxId = useProdigyMxCollectionId();
    const distId = useDistributorsCollectionId();

    const collId = useCurrentCollectionId();

    //console.log(mcId, mpId, mxId, collId);

    const dispatch = useDispatch();
    useEffect(() => {
        if (!collId) dispatch(setCurrentCollectionId(readCollectionId()));
    }, [collId, dispatch]);

    const type = useMemo(() => {
        switch (collId) {
            case mcId: return ChatType.PRODIGY_MC;
            case mpId: return ChatType.PRODIGY_MP;
            case mxId: return ChatType.PRODIGY_MX;
            case distId: return ChatType.DISTRIBUTORS;
            default: return undefined;
        }
    }, [collId, distId, mcId, mpId, mxId])

    return type;
}

export default function DotecChat() {
    const { logout } = useAuth0();
    const { threadId } = useParams();
    const type = useChatType();
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [text, setText] = useState("");
    const path = useRef();

    const logginOut = useCallback(() => {
        path.current = undefined;
        setText("Logging out");
        setShow(true);
    }, []);

    const clearing = useCallback(() => {
        path.current = type?.path;
        setText("Clearing the chat");
        setShow(true);
    }, [type?.path]);

    const closing = useCallback(() => {
        path.current = "../";
        setText("Closing the chat");
        setShow(true);
    }, []);

    const exit = useCallback(() => {
        if (path.current) navigate(path.current);
        else logout();
    }, [logout, navigate])

    return <div className="h-smallest-screen flex flex-col max-w-7xl mx-auto">
        <div className="text-white flex items-center justify-between border-b border-gray py-2 px-4 bg-blue">
            <div className="font-bold">{type?.text} AI</div>
            <div className="flex gap-x-5">
                <Buttoon className="bg-blue-light rounded-md align-middle text-sm py-0 hover:bg-blue-lightest w-auto h-9 px-3" onClick={logginOut}>
                    Logout
                </Buttoon>
                <Buttoon className="bg-blue-light rounded-md align-middle text-sm py-0 hover:bg-blue-lightest w-auto h-9 px-3" onClick={clearing}>
                    Clear
                </Buttoon>
                <Buttoon className="bg-orange rounded-md align-middle text-sm py-0 hover:bg-orange-pastel w-auto px-3" onClick={closing}>
                    Close
                </Buttoon>
            </div>
        </div>
        <div className="flex-grow flex flex-col mt-3 relative">
            <Logo className="absolute bottom-1/2 left-0 right-0 mx-auto opacity-[10%] w-[75%] fill-blue-dark stroke-black" />
            {type && <Chat threadId={threadId} />}
        </div>
        <ConfirmationDialog
            show={show}
            setShow={setShow}
            onConfirm={exit}
            title="Warning" text={text + " will discard your conversation history. Use Copy/Paste to save whatever you might need for future use!"}
            confirmText="Confirm"
        />
    </div>
}