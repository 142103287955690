import { useState, useEffect } from "react";
import RawMarkdownMessage from "./RawMarkdownMessage";
import { processMarkdown } from "components/content/markdown/MarkdownUtils";

export default function MarkdownMessage({ md, className = "", source = false, metadata = undefined }) {
    const [processedBody, setProcessedBody] = useState();

    useEffect(() => {
        //console.log("MD", md);
        processMarkdown(md).then((value) => setProcessedBody(value));
    }, [md]);

    return <RawMarkdownMessage content={processedBody} className={className} source={source} metadata={metadata} />
}
