import { useGetHomescreenTypeQuery } from "state/api/collections";
import Loading from "../Loading";
import ExcelVisualizer from "./ExcelVisualizer";
import { useParams } from "react-router-dom";
import { useCurrentCollectionId, useCurrentOrganizationId } from "state/GeneralSlice";
import StartChatting from "./StartChatting";
import Overview from "../Overview";

export default function Homescreen() {
    const { orgId, collectionId } = useParams();
    const organizationId = useCurrentOrganizationId();
    const collId = useCurrentCollectionId();

    const oId = orgId ?? organizationId;
    const cId = collectionId ?? collId;
    const { data } = useGetHomescreenTypeQuery({ collection_id: cId }, { skip: !cId });

    switch (data) {
        case "excel": return <ExcelVisualizer orgId={oId} collId={cId} />
        case "chat": return <StartChatting orgId={oId} collId={cId} />
        case "dashboard": return <Overview />
        default: <Loading />
    }
}