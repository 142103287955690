import { useState, useEffect } from "react";
import { useDeleteWFileMutation, useFilteredWorkingDocuments } from "../../../state/api/collections";
import MarkdownMessage from "../markdown/MarkdownMessage";
import Loading from "../Loading";
import Table from "../knowledge/Table";
import Pagination from "../knowledge/Pagination";
import ControlBar from "../knowledge/ControlBar";
export const DOCUMENTS_PER_PAGE = 5;

function GroupContainer({ description, children }) {
    return <div className="mb-5 mx-10">
        <div className="flex items-end gap-x-1">
            <div className="h-3 w-4 border-2 border-b-0 border-r-0 rounded-tl-md border-blue-light" />
            <h1 className="font-medium text-xl"><MarkdownMessage md={description} /></h1>
            <div className="h-3 flex-1 border-2 border-b-0 border-l-0 rounded-tr-md border-blue-light" />
        </div>
        <div className="border-2 rounded-b-md p-2 border-blue-light border-t-0">
            {children}
        </div>
    </div>
}


export default function WorkingFileList({ threadID, collectionID }) {
    // print thread id
    // return <div className="text-sm text-gray-500 mb-2"> Hello {threadID} </div>
    const [pageNumber, setPageNumber] = useState(1);
    const filteringPattern = '';
    const filteringIgnoreCase = true;
    const [sortingProperty, setSortingProperty] = useState("title");
    const [sortingDescending, setSortingDescending] = useState(true);
    const [deleteFile, { isLoading: isDeleting }] = useDeleteWFileMutation();

    const [_uploading, setUploading] = useState(false);
    const { isLoading, data: { documents, documentsCount } } = useFilteredWorkingDocuments(
        collectionID,
        threadID,
        DOCUMENTS_PER_PAGE,
        pageNumber,
        filteringPattern,
        filteringIgnoreCase,
        sortingProperty,
        sortingDescending,
    );

    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [editDocument, setEditDocument] = useState();

    useEffect(() => {
        if (documentsCount > 0) {
            setUploading(false);
        }
    }, [documentsCount]);

    return <GroupContainer description="Working Files">
        {isLoading || isDeleting ? <Loading /> : <>
            <ControlBar
                selectedDocuments={selectedDocuments}
                setSelectedDocuments={setSelectedDocuments}
                deleteFile={deleteFile}
                editDocument={editDocument}
                setEditDocument={setEditDocument}
                // selectedList={selectedList}
                title={null}
                subTitle={null}
                showAddNotes={false}
                addDocumentThreadId={threadID}
                wf={true}
            />
            {documentsCount > 0 ? <>
                <div className="flex flex-col items-stretch h-full max-h-full">
                    <div className="my-2"></div>
                    <Table
                        documents={documents}
                        selectedDocuments={selectedDocuments}
                        setSelectedDocuments={setSelectedDocuments}
                        sortingProperty={sortingProperty}
                        setSortingProperty={setSortingProperty}
                        sortingDescending={sortingDescending}
                        setSortingDescending={setSortingDescending}
                        onEditDocument={(doc) => setEditDocument(doc)}
                        autoGrow={false}
                        enableEdit={false}
                    />
                    {documentsCount > DOCUMENTS_PER_PAGE &&
                        <Pagination
                            itemsCount={documentsCount}
                            itemsPerPage={DOCUMENTS_PER_PAGE}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                        />}
                </div></> : <div className="h-2" />} </>}
    </GroupContainer>

}