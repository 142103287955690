import Buttoon from "components/Buttoon";
import { ReactComponent as Logo } from "./directout.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentOrganizationId, useCurrentOrganizationId } from "state/GeneralSlice";
import UsageOverview from "../UsageOverview";
import { useGetCurrentUserPrivateOrganizationQuery } from "state/api/organizations";
import Loading from "../Loading";
import { ArrowLeftOnRectangleIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetCurrentUserDetailsQuery } from "state/api/users";


const EMAIL_NOT_VERIFIED_AUTO_LOGOUT_SECONDS = 5;

export default function DirectOutDistributorsTotem() {
    const { data } = useGetCurrentUserPrivateOrganizationQuery();
    const orgId = useMemo(() => data?.uuid, [data?.uuid])
    const currentOrg = useCurrentOrganizationId();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setCurrentOrganizationId(orgId));
    }, [dispatch, orgId])

    const { logout } = useAuth0();
    const disconnect = useCallback(() => {
        localStorage.clear();
        logout({ logoutParams: { returnTo: window.location.origin } });
    }, [logout]);

    const navigate = useNavigate();

    const { error, isLoading } = useGetCurrentUserDetailsQuery();
    const notVerified = useMemo(() => error?.data?.detail === "email not verified", [error?.data?.detail]);

    const [startAutoLogout, setStartAutoLogout] = useState(false);
    const [logOutTimer, setLogOutTimer] = useState(EMAIL_NOT_VERIFIED_AUTO_LOGOUT_SECONDS);


    useEffect(() => {
        if (notVerified) {
            console.debug("Time for logout started");
            setStartAutoLogout(true);
        }
    }, [logout, notVerified]);

    useEffect(() => {
        if (startAutoLogout) {
            setInterval(() => {
                setLogOutTimer((o) => {
                    if (o === 0) return o;
                    return o - 1;
                });
            }, 1000);

            setTimeout(() => {
                localStorage.clear();
                logout({ logoutParams: { returnTo: window.location.origin } });
            }, EMAIL_NOT_VERIFIED_AUTO_LOGOUT_SECONDS * 1000)
        }
    }, [logout, startAutoLogout])

    return (
        <div className="w-full lg:w-1/2 mx-auto flex flex-col h-screen px-3 py-8 lg:pt-12 justify-between">
            <div className="mx-auto w-full fill-white">
                <Logo className="mx-auto" />
            </div>
            {isLoading ?
                <Loading />
                : <>
                    {notVerified
                        ? <div className="h-full text-white flex flex-col justify-center items-center text-2xl font-medium gap-y-2">
                            <ExclamationTriangleIcon className="w-36 stroke-azure" />
                            <div className="my-2" />
                            <span>Your email has not been verified</span>
                            <span>Please check your inbox. You will be redirected in {logOutTimer} seconds...</span>
                        </div>
                        : <>
                            {currentOrg === orgId
                                ? <>
                                    <UsageOverview orgId={orgId} hide={["Speech to Text", "Embedding", "Storage", "Collections", "User Creations"]} />
                                    <div className="">
                                        <div className="flex gap-x-5 justify-center items-center h-full gap-y-5">
                                            <Buttoon className="w-96 lg:h-60 h-36" onClick={() => navigate("distributors")}>
                                                <div className="text-lg font-normal flex flex-col gap-y-3">
                                                    <span>Chat with</span>
                                                    <span className="text-2xl font-bold">
                                                        DISTRIBUTORS
                                                    </span>
                                                    <span>Support</span>
                                                </div>
                                            </Buttoon>
                                        </div>
                                    </div>
                                </>
                                : <Loading />
                            }
                        </>
                    }
                </>
            }
            <div className="flex justify-center">
                <button
                    className="flex items-center gap-x-3 px-2 py-3 text-sm font-semibold text-white rounded-md hover:bg-orange"
                    onClick={disconnect}
                >
                    <ArrowLeftOnRectangleIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                    Logout
                </button>
            </div>
        </div>
    );
}
