import Buttoon from "components/Buttoon";
import { useEffect, useState, useCallback, useRef, useContext, createContext, useMemo } from "react";
import { useCurrentCollectionId } from "state/GeneralSlice";
import { useFetchSettingQuery, useResetSettingMutation } from "state/api/settings";
import ConfirmationDialog from "../ConfirmationDialog";
import { ManualConfigsKeys } from "./SettingComponentesMap";
import store from "state/store";
import collectionsRtkApi from "state/api/collections";
import { dispatch } from "use-bus";
import _ from "lodash";

export const RESET_VALUE = "\x1F";

export function getConfigFromState(state, config_name) {
    if (ManualConfigsKeys.includes(config_name)) return state?.manual_config[config_name];
    else return state?.current_config[config_name];
}

export function getStatePortion(state, config_name) {
    if (ManualConfigsKeys.includes(config_name)) return state?.manual_config;
    else return state?.current_config;
}

export function setConfigScope(state, config_name, scope) {
    if (!state.scope[scope]) state.scope[scope] = [];
    const scopePortion = state.scope[scope];
    if (!scopePortion.includes(config_name)) scopePortion.push(config_name);
}

//**************************************/
export async function updateModelPic(eventTarget, key) {
    const orgId = store.getState().general.currentOrganizationId;
    const collId = store.getState().general.currentCollectionId;
    const picture = eventTarget[key].files[0];

    if (picture) {
        return store.dispatch(collectionsRtkApi.endpoints.updateModelPic.initiate({ collection_id: collId, params: { current_org_uuid: orgId }, payload: { pic: picture } }))
    }
    return Promise.resolve({ data: true });
}

//**************************************/
export const SettingsContext = createContext();

export function useSettingState(setting) {
    const { values, setValues } = useContext(SettingsContext);

    const value = useMemo(() => getConfigFromState(values, setting.config_name) ?? RESET_VALUE, [setting.config_name, values]);
    const setValue = useCallback((v) => {
        setValues(prev => {
            //console.log("SET", setting.scope, setting.config_name, v);
            let value;
            if (typeof v === "function") value = v(getConfigFromState(prev, setting.config_name));
            else value = v;

            const tmp = _.cloneDeep(prev);
            const portion = getStatePortion(tmp, setting.config_name);
            delete portion[setting.config_name];
            portion[setting.config_name] = value;

            setConfigScope(tmp, setting.config_name, setting.scope);
            return tmp;
        });
    }, [setValues, setting.config_name, setting.scope]);

    return { value, setValue }
}

export function useFetchSetting(setting, value, setValue, onLoaded, additionalParams, additionalBody) {
    const collId = useCurrentCollectionId();
    const lastRequestId = useRef();

    const { currentData, requestId, isFetching } = useFetchSettingQuery({ collectionId: collId, scope: setting.scope, config_name: setting.config_name, params: additionalParams, body: additionalBody }, { refetchOnMountOrArgChange: true });

    const [lastData, setLastData] = useState(undefined);
    useEffect(() => {
        if (!isFetching) {
            if (currentData) {
                if (requestId === lastRequestId.current) return;
                lastRequestId.current = requestId;
                //console.log("FETCH", setting.config_name, currentData);
                setLastData(currentData[setting.config_name]);
            }
        }
    }, [currentData, isFetching, requestId, setting.config_name]);

    useEffect(() => {
        if (value === RESET_VALUE || value === undefined) {
            //console.log("RESET LAST DATA");
            setLastData(undefined);
        }
    }, [value])

    useEffect(() => {
        //console.log("PRECHANGE", lastData);
        if (lastData !== undefined) {
            if (value === RESET_VALUE || value === undefined) {
                //console.log("FETCHED CHANGED", setting.config_name, lastData);
                setValue(lastData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastData]);

    useEffect(() => {
        if (currentData !== undefined) onLoaded?.();
    }, [currentData, onLoaded]);
}

export function ResetSettingButton({ setting, onReset }) {
    const [show, setShow] = useState(false);
    const collId = useCurrentCollectionId();
    const [reset] = useResetSettingMutation();
    const handleConfirmation = useCallback((confirm) => {
        if (!confirm) return;
        onReset?.();
        dispatch("request-reset");
        reset({ collectionId: collId, scope: setting.scope, config_name: setting.config_name });
    }, [collId, onReset, reset, setting.config_name, setting.scope]);

    return <div>
        <Buttoon
            disabled={setting.disabled}
            className="bg-orange rounded-md h-6 text-xs align-middle py-0 hover:bg-orange-pastel w-28"
            onClick={() => setShow(true)}
        >
            Reset to Default
        </Buttoon>
        <ConfirmationDialog confirmText="Reset" show={show} setShow={setShow} onClose={handleConfirmation} />
    </div>
}

export function useTriggerSettingChangeCallback(value, setValue, onChange) {
    const requestTrigger = useRef(false);
    const triggerChange = useCallback((v) => {
        requestTrigger.current = true;
        setValue(v);
    }, [setValue])

    useEffect(() => {
        if (requestTrigger.current && value !== RESET_VALUE) {
            requestTrigger.current = false;
            onChange?.();
        }
    }, [onChange, value]);

    return triggerChange;
}