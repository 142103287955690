import ReactTextareaAutosize from "react-textarea-autosize";
import MarkdownMessage from "../markdown/MarkdownMessage";
import Buttoon from "components/Buttoon";
import classNames from "classnames";
import Loading from "../Loading";
import Modal from "../Modal";
import { useMessageActionMutation } from "state/api/threads";
import { useCurrentOrganizationId } from "state/GeneralSlice";
import { useCallback, useState } from "react";
import { PencilIcon } from "@heroicons/react/24/outline";

export default function EditMessageButton({ message, content }) {
    const [messageAction, { isLoading }] = useMessageActionMutation();
    const orgId = useCurrentOrganizationId();

    const parts = content.message_parts;

    const [show, setShow] = useState(false);
    const [error, setError] = useState();
    const handleSubmit = useCallback(async (event) => {
        event.stopPropagation();
        event.preventDefault();
        setError();

        const pars = [];
        parts.forEach((p, idx) => {
            let text;
            if (p.editable) text = event.target["part" + idx].value;
            else text = p.text
            pars.push({ text });
        });
        const body = { source: content.source, params: { message_parts: pars } };

        console.log(body);

        const response = await messageAction({
            thread_uuid: message.thread_uuid,
            message_uuid: message.uuid,
            action_name: "manual_edit",
            params: { current_org_uuid: orgId },
            body: body,
            metadata: content.cmetadata,
        }).unwrap();

        if (response.status === "ok") setShow(false);
        else setError(response.message);
    }, [content.cmetadata, content.source, message.thread_uuid, message.uuid, messageAction, orgId, parts]);

    return <>
        <button title="Edit" onClick={() => setShow(true)} className="text-blue-lightest hover:text-white">
            <PencilIcon className="w-4 h-4" />
        </button>
        <Modal show={show} setShow={setShow}>
            <form onSubmit={handleSubmit} className="min-w-[500px] w-full mx-auto">
                <p className="text-xl lg:text-2xl mb-1 mt-3 text-blue-lightest">Edit Message</p>
                <div className="border-t-2 border-blue my-2"></div>

                {parts.map((p, idx) => <MessagePart key={idx} part={p} idx={idx} />)}

                <div className="flex items-center justify-between mt-2 gap-x-2">
                    {isLoading
                        ? <Loading size="w-6 h-6" stroke="border-2" />
                        : <span className={classNames("text-orange-pastel text-sm text-justify", { "invisible": !error })}>{error}</span>
                    }
                    <div className="flex items-center gap-x-2">
                        <Buttoon
                            type="submit"
                            disabled={isLoading}
                            className="shrink-0 w-16"
                        >
                            Edit
                        </Buttoon>
                        <Buttoon
                            type="button"
                            disabled={isLoading}
                            className="shrink-0 w-16"
                            onClick={() => setShow(false)}
                        >
                            Cancel
                        </Buttoon>
                    </div>
                </div>
            </form>
        </Modal>
    </>
}

function MessagePart({ part, idx }) {
    if (!part.editable) return <MarkdownMessage md={part.text} />
    else return <ReactTextareaAutosize
        key={idx}
        id={"part" + idx}
        minRows={8}
        maxRows={8}
        className="block w-full rounded-md resize-none border-0 placeholder:text-blue-lightest focus:ring-0 sm:text-sm sm:leading-6 bg-blue"
        defaultValue={part.text}
    />
}